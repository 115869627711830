import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseURL = `${apiURL}/client/v1/campaign`;

export const createCampaign = async (body) => {
  const { data } = await axios.post(baseURL, body, {
    headers: setAuth(),
  });
  return data;
};

export const getCampaign = async () => {
  const { data } = await axios.get(baseURL, {
    headers: setAuth(),
  });
  return data;
};

export const getCampaignDetails = async (campaignId) => {
  const { data } = await axios.get(`${baseURL}/${campaignId}`, {
    headers: setAuth(),
  });
  return data;
};

export const deleteCampaign = async (campaignId) => {
  const { data } = await axios.delete(`${baseURL}/${campaignId}`, {
    headers: setAuth(),
  });
  return data;
};

export const updateCampaign = async (campaignId, updatedData) => {
  const { data } = await axios.put(`${baseURL}/${campaignId}`, updatedData, {
    headers: setAuth(),
  });
  return data;
};

export const activateCampaign = async (campaignId) => {
  const { data } = await axios.post(`${baseURL}/${campaignId}/status`, {}, {
    headers: setAuth(),
  });
  return data;
};

export const deactivateCampaign = async (campaignId) => {
  const { data } = await axios.delete(`${baseURL}/${campaignId}/status`, {
    headers: setAuth(),
  });
  return data;
};

export const addUserToCampaign = async (campaignId, { userIds }) => {
  const { data } = await axios.post(`${baseURL}/${campaignId}/user`, { userIds }, {
    headers: setAuth(),
  });
  return data;
};

export const removeUserFromCampaign = async (campaignId, { userIds }) => {
  const { data } = await axios.delete(
    `${baseURL}/${campaignId}/user`, 
    {
      data: { userIds },
      headers: setAuth(), 
    }
  );
  return data;
};

export const getUsersWithCampaign = async () => {
  const { data } = await axios.get(`${baseURL}/usersCampaign`, {
    headers: setAuth(),
  });
  return data;
};
export const setPriority = async (campaignId, priorityData) => {
  const { data } = await axios.put(`${baseURL}/${campaignId}/set-priority`,priorityData, 
   {
    headers:setAuth()
  });
  return data;
};