import React, { useEffect, useRef, useState } from "react";
import { FaCartPlus, FaRunning, FaRupeeSign } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoWallet } from "react-icons/io5";
import { MdLogout, MdOutlineNotificationsActive, MdOutlineSupportAgent } from "react-icons/md";
import { TbTrendingUp } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, useNavigate } from "react-router-dom";
import Faq from "../../../../components/Modal/Faq";
import { viewNotifications } from "../../../../reducers/notifications";
import { viewInventoryAlerts } from "../../../../reducers/products/MinimumStockReducer";
import { logoutUser } from "../../../../reducers/userReducer";
import {
  getPaymentStatus,
  getSubscriptionDetails,
} from "../../../../services/payment";
import { ReactComponent as SearchIcon } from "../../../public/img/SVG/magnifying-glass.svg";
import Logo from "../../../public/img/logo.png";
import FemaleAvatar from "./../../../public/img/female-avatar.png";
import MaleAvatar from "./../../../public/img/male-avatar.png";


const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedIn = useSelector(({ user }) => user);
  const minStock = useSelector((state) => state.settings["min-stock"]);
  const isCompanyName = useSelector((state) => state.settings["companyName"]);
  const companyName = useSelector((state) => state.companyName.companyName);
  const inventoryAlerts = useSelector(
    ({ inventoryAlerts }) => inventoryAlerts.data
  );
  const notifications = useSelector(({ notification }) => notification);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [gpsDetails, setGpsDetails] = useState();
  const [query, setQuery] = useState("")
  const [filteredPages, setFilteredPages] = useState([]); 
  const [debouncedQuery, setDebouncedQuery] = useState("");
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getSubscriptionDetails();
        setGpsDetails(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (loggedIn.user.des === 101) {
      getPaymentStatus().then(({ data }) => setPaymentStatus(data));
    }
  }, [loggedIn]);

  const [variant, setVariant] = useState(undefined);

  useEffect(() => {
    dispatch(viewInventoryAlerts());
    dispatch(viewNotifications());
  }, [dispatch]);

  const list = [
    {name:"Notification", path:"notification"},
    {name:"Today's Activity", path:"today's-activity"},
    {name:"Minimum Stock", path:"min-stock"},
    {name:"Consolidated Reports", path:"consolidated-reports"},
    {name:"Navigate To Tracker", path:"navigate-to-tracker"},
    {name:"Profile", path:"profile"},
    {name:"Wallet", path:"Wallet"},
    {name:"Help & Support", path:"help-&-support"},
    
    {name:"Tour Program", path:"tour-program"},
    {name:"Call Planning", path:"call-planning"},
    {name:"Expenses", path:"expenses"},
    {name:"Primary Sales", path:"primarysales"},
    {name:"All Primary Sales", path:"primarysales/all"},
    {name:"Upload Primary Sales", path:"primarysales/upload-ps"},
    {name: "Secondary Sales", path:"secondarysales"},
    {name:"All Secondary Sales", path:"secondarysales/all"},
    {name:"Upload Secondary Sales", path:"secondarysales/upload-ss"},
    {name:"E-Detailing", path:"e-detailing"},
    {name:"Sales", path:"sales"},
    {name:"Primary Sales", path:"sales/primarysales"},
    {name:"All Primary Sales", path:"sales/primarysales/all"},
    {name:"Gross Primary Sales", path:"sales/gross-primarysales"},
    {name:"Secondary Sales", path:"sales/secondarysales"},
    {name:"All Secondary Sales", path:"sales/secondarysales/all"},
    {name:"Gross Secondary Sales", path:"sales/gross-secondarysales"},

    {name:"Reports", path:"utilities"}, 
    {name:"Monthly Call Reports", path:"utilities/monthly-reports"},
    {name:"Annual Call Reports", path:"utilities/annual-reports"},
    {name:"Geo Location Analysis Reports", path:"utilities/geolocation-reports"},
    {name:"Expense Reports", path:"utilities/monthly-expense-report"},
    {name:"Tour Program Reports", path:"utilities/tour-program"},

    {name:"Call Reports", path:"utilities/call-reports"},
    {name:"Reminder Call Reports", path:"utilities/extra-activity-dcr"},
    // {name:"Reports", path:"utilities/sales"}, //
    {name:"Missed Reports", path:"utilities/missed-doctor-reports"},
    {name:"Stockist Wise Sales", path:"utilities/stockist-wise-sales"},
    {name:"Secondary Sales Reports", path:"utilities/secondary-sales-reports"},
    {name:"Stockist Wise Sales Reports", path:"utilities/secondary-sales-reports/stk"},
    {name:"Primary Sales Reports", path:"utilities/primary-sales-reports"},
    {name:"Stockist Wise Sales Reports", path:"utilities/primary-sales-reports/stk"},
    {name:"Product Wise Sales", path:"utilities/product-wise-sales"},
    {name:"Product Wise Primary Sales", path:"utilities/product-wise-sales/primary-sales"},
    {name:"Product Wise Secondary Sales", path:"utilities/product-wise-sales/secondary-sales"},
    {name:"POB Reports", path:"utilities/pob-reports"},
    {name:"Target Vs Achievements", path:"utilities/targets-achievements"},
    {name:"Doctor Wise Sales", path:"utilities/doctor-wise-sales"},
    {name:"Lists", path:"utilities/lists"},
    {name:"Doctors List", path:"utilities/lists/doctors-list"},
    {name:"Chemists List", path:"utilities/lists/chemists-list"},
    {name:"Stockists List", path:"utilities/lists/stockists-list"},
    {name:"Product List", path:"utilities/lists/products-list"},
    {name:"Geo Fancing", path:"utilities/lists/geo-fencing"},
    {name:"Hq City Local Area List", path:"utilities/lists/hq-city-localArea-list"},
    {name:"Gifts List", path:"utilities/lists/gift-list"},
    {name:"Route List", path:"utilities/lists/route-list"},
    {name:"Holiday List", path:"utilities/lists/holiday-list"},
    {name:"Targets", path:"utilities/targets"},
    {name:"Target Reports", path:"utilities/targets/user-target"},
    {name:"Headquarter targets", path:"utilities/targets/user-target/hq-user"},
    {name:"Samples Allotted", path:"utilities/targets/allotted-samples"},
    {name:"Gifts Allotted", path:"utilities/targets/allotted-gifts"},
    {name:"Archived Target", path:"utilities/targets/achieved-target"},
    {name:"CheckIn / CheckOut Reports", path:"utilities/punching-report"},
    {name:"Call Planning Reports", path:"utilities/call-planning"},
    {name:"DCS Duplicate Entries", path:"utilities/duplicate-entries"},
  

    {name:"Attendance", path:"attendance"},
    {name:"Reminders", path:"reminders"},
    {name:"Leave Request", path:"leave-request"},
    {name:"Feed Stocks", path:"stock-management"}, 
    {name:"Sample Management", path:"sample-management"},
    {name:"Gift Management", path:"gift-management"},


    {name:"CRM", path:"crm"},
    {name:"Manage CRM", path:"crm/doctor"},
    {name:"Business", path:"crm/doctor/business"},
    {name:"Payments", path:"crm/doctor/payments"},
    {name:"Manage Report", path:"crm/report"},
    {name:"Doctor Sales", path:"crm/report/doctor-sales"},
    {name:"Payment Report", path:"crm/report/payment-report"}, 
    {name:"Settings", path:"crm/settings"},
    {name:"Chemist Sales", path:"crm/settings/chemist-sales"},
    {name:"Doctor Product", path:"crm/settings/doctor-product"},
    {name:"Chemist Doctor", path:"crm/settings/chemist-doctor"},
    {name:"Stokist Chemist", path:"crm/settings/stokist-chemist"},

    {name:"Profit Analysis", path:"profit-analysis"},
    {name:"Manage Cost Center", path:"profit-analysis/cost-center"},
    {name:"Employee", path:"profit-analysis/cost-center/employee"},
    {name:"Doctor", path:"profit-analysis/cost-center/doctor"},
    {name:"Chemist", path:"profit-analysis/cost-center/chemist"},
    {name:"Stockist", path:"profit-analysis/cost-center/stockist"},
    {name:"Miscellaneous", path:"profit-analysis/cost-center/miscellaneous"},
    {name:"Total Expenses", path:"profit-analysis/cost-center/total-expenditure"},

    {name:"Manage Profit Center", path:"profit-analysis/profit-center"},
    {name:"Products", path:"profit-analysis/profit-center/products"},
    {name:"Roi", path:"profit-analysis/profit-center/roi"}, 

    {name:"Setting", path:"profit-analysis/settings"},
    {name:"Custom Cost", path:"profit-analysis/settings/custom-cost"}
   

  ]


  if(loggedIn.user.isAdmin === true){
     list.push( {name:"Admin", path:"admin-panel"},
     {name:"Locations", path:"admin-panel/locations"},
     {name:"Create State", path:"admin-panel/locations/create-state"},
     {name:"Create HeadQuarter", path:"admin-panel/locations/create-hq"},
     {name:"Create City", path:"admin-panel/locations/create-city"},
     {name:"Create Route", path:"admin-panel/locations/create-route"},
     
     {name:"Manage Products", path:"admin-panel/products"}, 
     {name:"Product Category", path:"admin-panel/products/category"},
     {name:"Product Type", path:"admin-panel/products/type"},
     {name:"Upload Products", path:"admin-panel/products/upload-products"},
     {name:"Product Supplier", path:"admin-panel/products/add-supplier"},
     {name:"Inventory", path:"admin-panel/products/inventory"},
     {name:"All Inventory", path:"admin-panel/products/inventory/full"}, 
     {name:"Product", path:"admin-panel/products/range"},
     {name:"Min Stock", path:"admin-panel/products/min-stock"},
     
     {name:"Create Holidays", path:"admin-panel/holidays/create"}, 
     {name:"Preferences", path:"admin-panel/holidays/preferences"},
     {name:"Admin's Backlog Reports", path:"admin-panel/holidays/backlog-reports"},
     {name:"User Controls", path:"admin-panel/holidays/user-controls"},
     {name:"Set Maximum Visits For Routes", path:"admin-panel/holidays/preferences/max-tour-limit"},
     {name:"Restore Deleted", path:"admin-panel/holidays/restore-deleted"},
     {name:"Doctor Controls", path:"admin-panel/holidays/doctor-controls"},
 
     {name:"Create Campaign", path:"admin-panel/holidays/campaign"},
     {name:"Campaigns", path:"admin-panel/holidays/allcampaign"},
  
     {name:"Manage Expenses", path:"/admin-panel/expenses"},
     {name:"Travel Allowances", path:"/admin-panel/expenses/travel-allowances"},
     {name:"Out Station Allowances", path:"/admin-panel/expenses/out-station-allowances"},
     {name:"Rates", path:"/admin-panel/expenses/rates"}, 
    
   
     {name:"Manage Users", path:"/admin-panel/users"}, 
     {name:"Create User", path:"/admin-panel/users/create-user"}, 
     {name:"Edit User", path:"/admin-panel/users/edit-user"},  
     {name:"Create Admin", path:"/admin-panel/users/create-admin"}, 
     {name:"Admin Info", path:"/admin-panel/users/admin-info"},
     {name:"User Info", path:"/admin-panel/users/user-info"}, 
     {name:"Manage Division", path:"/admin-panel/users/division"}, 
     {name:"Manage Designation", path:"/admin-panel/users/designation"}, 
     {name:"Upload Target", path:"/admin-panel/users/upload-target"},
     {name:"Set User Target", path:"/admin-panel/users/set-user-target"},
     // {name:"yearly", path:"/admin-panel/users/set-user-target/yearly-targets"},
     // {name:"b", path:"/admin-panel/users/set-user-target/monthly-user-target"},
     {name:"Set Headquarter Target", path:"/admin-panel/users/set-headquarter-target"},
     // {name:"yearls hq target", path:"/admin-panel/users/set-headquarter-target/yearly-hq-targets"},
     // {name:"monthly Headquarter Target", path:"/admin-panel/users/set-headquarter-target/monthly-hq-targets"},
     {name:"Access Control", path:"/admin-panel/users/access-control"}, 
     {name:"User Devices", path:"/admin-panel/users/user-devices"},
     {name:"Family", path:"/admin-panel/users/family"}, //
     {name:"Billing", path:"/admin-panel/users/billing"},
 
     {name:"Manage Doctors, Stockists & Chemists", path:"/admin-panel/dcs"},
     {name:"E-Detailing Management", path:"/admin-panel/dcs/admin-e-detailing-management"},
     {name:"Create Doctors", path:"/admin-panel/dcs/create-doctors"},
     {name:"Create Chemists", path:"/admin-panel/dcs/create-chemists"},
     {name:"Create Stockist", path:"/admin-panel/dcs/create-stockists"},
     {name:"View Doc Chem Stk", path:"/admin-panel/dcs/view-dcs"},
     {name:"Upload DCS City / Area", path:"/admin-panel/dcs/upload-dcs"},
     {name:"Edit / Delete DCS", path:"/admin-panel/dcs/edit-delete"}, 
     {name:"Upload Images", path:"/admin-panel/dcs/upload-images"}, 
     {name:"DCS List Management", path:"/admin-panel/dcs/dcs-list-management"},
     {name:"Allot DCS", path:"/admin-panel/dcs/dcs-list-management/allot-dcs"},
     {name:"De-Allot DCS", path:"/admin-panel/dcs/dcs-list-management/de-allot-dcs"},
     {name:"Allot DCS", path:"/admin-panel/dcs/dcs-list-management/allot-dcs"},
     {name:"Transfer DCS", path:"/admin-panel/dcs/dcs-list-management/transfer-dcs"},
 
     {name:"Manage Samples & Gifts", path:"/admin-panel/ssg"},
     {name:"Create Gift", path:"/admin-panel/ssg/create-gift"},
     {name:"Allot Samples", path:"/admin-panel/ssg/allot-samples"},
     {name:"Allot Gifts", path:"/admin-panel/ssg/allot-gifts"},
 
     {name:"Approvals", path:"/admin-panel/approvals"}, 
     {name:"Approve Call Report", path:"/admin-panel/approvals/approve-call-report"},
     {name:"Approve Tour Program", path:"/admin-panel/approvals/approve-tp"},
     {name:"Approve Call Planning", path:"/admin-panel/approvals/approve-cp"},
     {name:"Approve Doctors", path:"/admin-panel/approvals/approve-doctors"},
     {name:"Approve Chemists", path:"/admin-panel/approvals/approve-chemists"},
     {name:"Approve Stockists", path:"/admin-panel/approvals/approve-stockists"},
     {name:"Approve Expense", path:"/admin-panel/approvals/approve-expense"},
     {name:"Approve Leave Request", path:"/admin-panel/approvals/approve-leave-request"},
     {name:"approve City", path:"/admin-panel/approvals/approve-city"},
     {name:"Approve Route", path:"/admin-panel/approvals/approve-route"},
     {name:"Approve Geo Fencing", path:"/admin-panel/approvals/approve-geo-fencing"},
     {name:"Approve Gifts", path:"/admin-panel/approvals/gifts-approve"},
     {name:"Approve Samples", path:"/admin-panel/approvals/samples-approve"},
     {name:"Approve Primary Sale", path:"/admin-panel/approvals/approve-primary-sale"},
     {name:"Approve Secondary Sale", path:"/admin-panel/approvals/approve-secondary-sale"},
     {name:"Deletion Request", path:"/admin-panel/approvals/deletion-request"},)
  }else{
    if(loggedIn.user.des !== 121){
      list.push({name:"Creation Menu", path:"/creation-menu"}, 
      {name:"Area Creation", path:"/creation-menu/locations"}, 
      {name:"Create City", path:"/creation-menu/locations/create-city"},
      {name:"Create Route", path:"/creation-menu/locations/create-route"}, 
      {name:"Manage Samples & Gifts", path:"/creation-menu/ssg"},  
      {name:"Create Gift", path:"/creation-menu/ssg/create-gift"},
      {name:"Allot Samples", path:"/creation-menu/ssg/allot-samples"},
      {name:"Allot Gifts", path:"/creation-menu/ssg/allot-gifts"},
  
      {name:"Manage Doctors, Stockists & Chemists", path:"/creation-menu/dcs"},
      {name:"Create Doctors", path:"/creation-menu/dcs/create-doctors"},
      {name:"Create Chemists", path:"/creation-menu/dcs/create-chemists"},
      {name:"Create Stockists", path:"/creation-menu/dcs/create-stockists"},
      {name:"View Doc Chem Stk", path:"/creation-menu/dcs/view-dcs"},
      {name:"Edit / Delete DCS", path:"/creation-menu/dcs/edit-delete"},
      {name:"Doctor Wise Product List", path:"/creation-menu/dcs/doctor-wise-products-list"},
  
      {name:"Approvals", path:"/creation-menu/approvals"},
      {name:"Approve Call Report", path:"/creation-menu/approvals/approve-call-report"},
      {name:"Approve Tour Program", path:"/creation-menu/approvals/approve-tp"},
      {name:"Approve Call Planning", path:"/creation-menu/approvals/approve-cp"},
      {name:"Approve Doctors", path:"/creation-menu/approvals/approve-doctors"},
      {name:"Approve Chemists", path:"/creation-menu/approvals/approve-chemists"},
      {name:"Approve Stockists", path:"/creation-menu/approvals/approve-stockists"},
      {name:"Approve Expense", path:"/creation-menu/approvals/approve-expense"},
      {name:"Approve Leave Request", path:"/creation-menu/approvals/approve-leave-request"},
      {name:"Approve City", path:"/creation-menu/approvals/approve-city"},
      {name:"Approve Route", path:"/creation-menu/approvals/approve-route"},
      {name:"Approve Samples", path:"/creation-menu/approvals/samples-approve"},
      {name:"Approve Gifts", path:"/creation-menu/approvals/gifts-approve"},
      {name:"Deletion Request", path:"/creation-menu/approvals/deletion-request"},
  
      {name:"Settings", path:"/creation-menu/settings"},
      {name:"Backlog Reports", path:"/creation-menu/settings/backlog-reports"},)

    }else{
      list.push({name:"Creation Menu", path:"/creation-menu"}, 
      {name:"Area Creation", path:"/creation-menu/locations"}, 
      {name:"Create City", path:"/creation-menu/locations/create-city"},
      {name:"Create Route", path:"/creation-menu/locations/create-route"}, 
      {name:"Manage Samples & Gifts", path:"/creation-menu/ssg"},  
      {name:"Create Gift", path:"/creation-menu/ssg/create-gift"},
      {name:"Allot Samples", path:"/creation-menu/ssg/allot-samples"},
      {name:"Allot Gifts", path:"/creation-menu/ssg/allot-gifts"},
  
      {name:"Manage Doctors, Stockists & Chemists", path:"/creation-menu/dcs"},
      {name:"Create Doctors", path:"/creation-menu/dcs/create-doctors"},
      {name:"Create Chemists", path:"/creation-menu/dcs/create-chemists"},
      {name:"Create Stockists", path:"/creation-menu/dcs/create-stockists"},
      {name:"View Doc Chem Stk", path:"/creation-menu/dcs/view-dcs"},
      {name:"Edit / Delete DCS", path:"/creation-menu/dcs/edit-delete"},
      {name:"Doctor Wise Product List", path:"/creation-menu/dcs/doctor-wise-products-list"},
  
      {name:"Settings", path:"/creation-menu/settings"},
      {name:"Backlog Reports", path:"/creation-menu/settings/backlog-reports"},)
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300); 

    return () => {
      clearTimeout(handler);
    };
  }, [query]);
  
  // const handleSearch = (e) =>{
  //   // setQuery(e.target.value)
  //   const value = e.target.value.toLowerCase();
  //   setQuery(value)
  //   if (value) {
  //     const regex = new RegExp(`${value}`, "i");
  //     console.log(regex)
  //     const results = list.filter((page) => regex.test(page.name)); 
  //     setFilteredPages(results);
  //     // setShowSuggestions(true);
  //   } else {
  //     setFilteredPages([]);
  //   }
  // }
  useEffect(() => {
    if (debouncedQuery) {
      const regex = new RegExp(`${debouncedQuery}`, "i");
      const results = list.filter((page) => regex.test(page.name));
      setFilteredPages(results);
    } else {
      setFilteredPages([]);
    }
  }, [debouncedQuery]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setQuery(value);
  };
  const handleSelectPage = () => {
    setFilteredPages([]); 
    setQuery("")
  };
 
  

  return (
    <header className="header">
      <div className="brand" onClick={() => navigate("/", { replace: true })}>
        <img src={Logo} alt="Medorn Logo" className="logo" />
        <h1 className="brand__heading">
          {isCompanyName === "true" ? (
            companyName?.includes(" ") ? (
              <>
                <span style={{ display: "block", lineHeight: "1" }}>
                  {companyName.split(" ")[0]}
                </span>
                <span
                  style={{
                    display: "block",
                    fontSize: "10px",
                    fontWeight: "700",
                    color: "#2ecc71bb",
                    opacity: "0.7",
                  }}
                >
                  {companyName.split(" ").slice(1).join(" ")}
                </span>
              </>
            ) : (
              companyName
            )
          ) : (
            "Medorn"
          )}
        </h1>
      </div>
     
      <form action="#" className="search position-relative ">
        <input
          type="search"
          className="search__input  text-white"
          placeholder="Search Doctors, Tour Program....."
          onChange={handleSearch}
   
        />
        <button className="search__button">
          <SearchIcon className="search__icon" />
        </button>
        {filteredPages.length > 0 && (
          <div
            className="search-results position-absolute  shadow-sm w-100 overflow-auto rounded-lg"
            style={{ top: "100%", zIndex: 10, maxHeight: "400px", backgroundColor:"#25253D", borderRadius: "12px" }}
            >
            {filteredPages.map((page, index) => (
            <div key={index} className="search-result-item px-4 py-3">
              <Link  to={page.path} onClick={handleSelectPage} className="text-dark text-decoration-none">
                <p className=" mb-0 text-white">{page.name}</p>
                <p className=" mb-0 text-white-50" style={{ fontSize: "1rem" }} >
                {page.path
                .split("/") 
                .filter(segment => segment) 
                .map(word => 
                word
                .replace(/-/g, " ")
                .split(" ") 
                .map(part => part.charAt(0).toUpperCase() + part.slice(1)) 
                .join(" ") )
                .join(" → ")} 
                </p>
             </Link>
            </div>
           ))}
          </div>
        )}
      </form>
      <nav className="user-nav">
        {loggedIn?.user?.des === 101 && <Link to="/help-&-support" className="user-nav__icon-box">
          <MdOutlineSupportAgent className="user-nav__icon" />
        </Link>}
        {paymentStatus?.status === "Unpaid" && (
          <Link to="/admin-panel/users/billing" className="user-nav__icon-box">
            <FaRupeeSign className="user-nav__icon icon-color-error" />
            <span className="user-nav__notification">1</span>
          </Link>
        )}
        
        {loggedIn?.user?.isAdmin === true &&  <Link to="/wallet" className="user-nav__icon-box">
          <IoWallet className="user-nav__icon" />
        </Link>}
       

        {gpsDetails?.length > 0 && gpsDetails[0]?.gps === true && (
          <Link to="/navigate-to-tracker" className="user-nav__icon-box">
            <FaLocationDot className="user-nav__icon" />
          </Link>
        )}

        {minStock === "true" && (
          <Link to="/min-stock" className="user-nav__icon-box">
            <FaCartPlus className="user-nav__icon" />
            <span className="user-nav__notification">
              {inventoryAlerts.length}
            </span>
          </Link>
        )}
        {/* started from here   */}
        <Link
          to="/consolidated-reports"
          className="user-nav__icon-box"
          onClick={() => setVariant("soft")}
        >
          <TbTrendingUp className="user-nav__icon" size={24} />
          {/* <BsQuestionCircleFill className="user-nav__icon" size={24} /> */}
        </Link>

        <Link to="/today's-activity" className="user-nav__icon-box">
          <FaRunning className="user-nav__icon" />
        </Link>
        <Link to="/notification" className="user-nav__icon-box">
          <MdOutlineNotificationsActive className="user-nav__icon" />
          <span className="user-nav__notification">
            {notifications?.alerts?.length || 0}
          </span>
        </Link>
        <div onClick={handleLogout} className="user-nav__icon-box">
          <MdLogout className="user-nav__icon" />
        </div>
        <Link to="/profile">
          <div className="user-nav__user">
            <img
              src={loggedIn?.user?.profileImg || (loggedIn?.user?.gender?.toLowerCase() === "male" ? MaleAvatar : FemaleAvatar)}
              alt="avatar"
              className="user-nav__user-photo"
            />
            <span className="user-nav__user-name">
              {loggedIn.user.firstName}
            </span>
          </div>
        </Link>
      </nav>
      <Faq variant={variant} setVariant={setVariant} />
    </header>
  );
};

export default Header;
