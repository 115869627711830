import {
  approveTarget,
  deleteTarget,
  editTarget,
  newTargetForApproval,
  newTargetV1,
  rejectTarget,
  uploadTarget,
  viewAllTargets,
  viewTargets,
  viewTargetsForApproval
} from "../../services/targets.js";
import { approveCount } from "../approveCountReducer.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DOCTORS":
      return action.payload;

    case "SET_DOCTORS_ALL":
      return action.payload;

    case "SET_DOCTORS_FOR_APPROVAL":
      return action.payload;

    case "CREATE_DOCTOR":
      return { data: [...state.data, action.payload.data.data] };

    case "UPLOAD_DOCTOR":
      return { data: [...state.data, action.payload.data.data] };

    case "CREATE_DOCTOR_FOR_APPROVAL":
      return { data: [...state.data, action.payload.data.data] };

    case "CLEAR_DOCTORS":
      return initialState;

    case "EDIT_DOCTORS":
      return { ...state, newState: action.payload };

    case "DELETE_DOCTORS":
      return action.payload;

    case "APPROVE_DOCTOR":
      return {data:state.data};

    case "REJECT_DOCTOR":
      return {data:state.data};

    default:
      return state;
  }
};

export const setDoctorsForApproval = (filters) => {
  return async (dispatch) => { 
    const { data } = await viewTargetsForApproval("doctor", filters);

    dispatch({
      type: "SET_DOCTORS_FOR_APPROVAL",
      payload: { data: data },
    });
    dispatch(approveCount()) 
  };
};

export const setDoctors = (filters) => {
  return async (dispatch) => {
    const { data } = await viewTargets("doctor", filters);
    dispatch({
      type: "SET_DOCTORS",
      payload: { data: data },
    });
  };
};

export const setAllDoctors = (filters) => {
  return async (dispatch) => {
    const { data } = await viewAllTargets("doctor", filters);
    
    dispatch({
      type: "SET_DOCTORS_ALL",
      payload: { data: data },
    });
  };
};

export const addDoctor = (data) => {
  return async (dispatch) => {
    try {
      // data = await newTarget("doctor", data);
      data = await newTargetV1("doctor", data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_DOCTOR",
      payload: { data: data },
    });
  };
};

export const uploadDoctors = (data, userId) => {
  return async (dispatch) => {
    try {
      data = await uploadTarget("doctor", data, userId);
    } catch (e) {
      throw e.response;
    }

    dispatch({
      type: "UPLOAD_DOCTOR",
      payload: { data: data },
    });
    return data ;
  };
};

export const addDoctorForApproval = (data) => {
  return async (dispatch) => {
    try {
      data = await newTargetForApproval("doctor", data);
    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "CREATE_DOCTOR_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const clearDoctors = () => {
  return {
    type: "CLEAR_DOCTORS",
  };
};

export const editDoctor = (id, updatedTarget) => {
  return async (dispatch) => {
    try {
      updatedTarget = await editTarget("doctor", id, updatedTarget);
    } catch (e) {
      if (updatedTarget.error) throw updatedTarget.error;
    }

    dispatch({
      type: "EDIT_DOCTORS",
      payload: { data: updatedTarget },
    });
    dispatch(setDoctors());
  };
};

export const deleteDoctor = (id, data) => {
  return async (dispatch) => {
    try {
      data = await deleteTarget("doctor",id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_DOCTORS",
      payload: { data: data },
    });
    dispatch(setDoctors());
  };
};

export const approveDoctor = (id, data) => {
  return async (dispatch) => {
    try {
      data = await approveTarget("doctor", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_DOCTOR",
      payload: { data: data },
    });
    dispatch(setDoctorsForApproval());
  };
};

export const rejectDoctor = (id, data) => {
  return async (dispatch) => {
    try {
      data = await rejectTarget("doctor", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_DOCTOR",
      payload: { data: data },
    });
    dispatch(setDoctorsForApproval());
  };
};

export default reducer;
