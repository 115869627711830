import React from "react";
import ExcelExportButton from "../ExcelExport";

const CustomTable = ({ tableData }) => {
  return (
    <div className="custom-table-styling-for-CR">
      <table style={{fontSize:"15px"}}>
        <thead>
          <tr>
            <th>Sr</th>
            <th>Submitter</th>
            <th>ActivityType</th>
            <th>AreaType</th>
            <th>Date</th>
            <th>Time</th>
            <th>Type</th>
            <th>Name</th>
            <th>Products</th>
            <th>POB</th>
            <th>Samples</th>
            <th>Gifts</th>
            <th>Remarks</th>
            <th>Work Areas</th>
            <th>Worked With</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, index) => (
            <React.Fragment key={index}>
              {item?.doctors?.map((doctor, doctorIndex) => (
                <TableRow
                  key={doctorIndex}
                  data={item}
                  type="Doctor"
                  rowData={doctor}
                />
              ))}
              {item?.chemists?.map((chemist, chemistIndex) => (
                <TableRow
                  key={chemistIndex}
                  data={item}
                  type="Chemist"
                  rowData={chemist}
                />
              ))}
              {item?.stockists?.map((stockist, stockistIndex) => (
                <TableRow
                  key={stockistIndex}
                  data={item}
                  type="Stockist"
                  rowData={stockist}
                />
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {/* <ExcelExportButton tableData={tableData} /> */}
    </div>
  );
};

const TableRow = ({ data, type, rowData }) => {
  const commonValues = (
    <React.Fragment>
      <td>{data.sr}</td>
      <td>{data.submitter.firstName} {data.submitter.lastName}</td>
      <td>{data.activity}</td>
      <td>{data.areaType}</td>
      <td>{data.date}</td>
    </React.Fragment>
  );

  return (
    <tr>
      {commonValues}
      <td>
        {new Date(rowData.cdt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </td>
      <td
        className={
          type === "Doctor"
            ? "text-success"
            : type === "Chemist"
            ? "text-danger"
            : "text-warning"
        }
      >
        {type}
      </td>
      <td>{
        type === "Doctor"
        ? rowData?.target?.name
        : rowData?.target?.businessName
        }</td>
      <td>
        {
          rowData?.products
            ?.filter(product => (product.quantity && product.quantity !== "0" && product.quantity !== ""))
            ?.map(
              (product) =>
                `${product?.productName}: ${product.quantity} ( ₹ ${product.amount} )`
            )
            ?.join(", ")
        }

      </td>
      <td>{`₹${rowData?.products?.reduce(
        (sum, product) => sum + product?.amount,
        0
      )}`}</td>
      <td>
        {
          rowData?.products
            ?.filter((product) => product?.sampleQuantity)
            .map(
              (product) => `${product?.productName}: ${product?.sampleQuantity}`
            )
            .join(", ")
        }
      </td>
      <td>
        {rowData?.gifts
          ?.map((product) => `${product?.giftName}: ${product?.giftQuantity} `)
          .join(", ")}
      </td>
      <td>{rowData.remarks}</td>
      <td>{data?.workAreas?.map((area) => area.location.label).join(", ")}</td>
      <td>{rowData?.workedWith?.map((user) => user.firstName).join(", ")}</td>
      <td>{rowData?.target?.address}</td>
    </tr>
  );
};

export default CustomTable;
