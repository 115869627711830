import moment from "moment-timezone";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCircleExclamation } from "react-icons/fa6";
import { IoMdSave } from "react-icons/io";
import { MdArrowBackIos } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import * as XLSX from "xlsx";
import customToast from "../../../../components/CustomToast";
import Table from "../../../../components/Table";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../../reducers/locations/hqReducer";
import {
  clearStockists,
  setStockists,
} from "../../../../reducers/targets/stockist";
import {
  clearDivisions,
  setDivisions,
} from "../../../../reducers/users/divisionReducer";
import { viewProductV1, viewProducts } from "../../../../services/products";
import {
  addSecondarySales,
  getSecondarySalesLatestMonth,
  getSecondarySalesPrevMonth,
} from "../../../../services/sales";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import {
  addIndex,
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";

const headerSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "none",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",
    width: "100%",
    cursor: "pointer",
    outline: "none",
    border: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    outline: "none",
    border: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    "&:hover": {
      color: "#9E9AD1",
    },
    backgroundColor: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "5px",
    height: "5px",
    padding: "0",
    "&:first-child": {
      width: "100% !important",
      height: "100% !important",
    },
  }),
};

const dropdownOptions = [
  { label: "MRP", value: "mrp" },
  { label: "PTS", value: "pts" },
  { label: "PTR", value: "ptr" },
  { label: "CUSTOM", value: "cus" },
];

const UploadSecondarySales = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const stockists = useSelector(({ stockist }) => stockist);
  const headquarters = useSelector(({ headquarter }) => headquarter);
  const secondary = useSelector((state) => state.settings["secondary"]);
  const divisionData = useSelector(({ division }) => division);
  const loggedIn = useSelector(({ user }) => user);
  const [division, setDivision] = useState(null);
  const [headquarter, setHeadquarter] = useState();
  const [stk, setstk] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [uploadData, setUploadData] = useState([]);
  const [prodArray, setProdArray] = useState([]);
  const [prevSales, setPrevSales] = useState([]);
  const [priceHeader, setPriceHeader] = useState({
    label: "PTS",
    value: "pts",
  });
  const [salesValue, setSalesValue] = useState({});
  const [closingValue, setClosingValue] = useState({});
  const [prodPrice, setProdPrice] = useState({});
  const [totalValue, setTotalValue] = useState({});
  const [customPrices, setCustomPrices] = useState({});
  const [editPrice, setEditPrice] = useState(false);
  // useEffect(() => {
  //   if(!division && loggedIn?.user?.des !== 101){
  //     setDivision({value:loggedIn?.user?.division})
  //   }
  // }, [])
  

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await viewProducts("");
      setProdArray(res?.data);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    dispatch(setHeadquarters());
    dispatch(setStockists());
    dispatch(setDivisions());
    return () => {
      dispatch(clearHeadquarters());
      dispatch(clearStockists());
      dispatch(clearDivisions());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          month: month?.value,
          year: Number(year?.label),
          stockist: stk?._id,
          headquarter: headquarter?._id,
        };

        const res = await getSecondarySalesPrevMonth(payload);
        setPrevSales(res?.data);
      } catch (error) {
        setMonth(null);
        if (error?.response?.data?.message) {
          return customToast.error(error?.response?.data?.message);
        }
      }
    };
    if (month && year && headquarter && stk) {
      fetchData();
    }
  }, [month, year, headquarter, stk]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let preMonth = Number(month?.value);
        let preYear = Number(year?.label);
        const { data: latestData } = await getSecondarySalesLatestMonth({
          month: Number(month?.value),
          year: Number(year?.label),
          headquarter: headquarter?._id,
          stockist: stk?._id,
        });
        if (latestData && preYear < latestData?.year) {
          setMonth("");
          return customToast.error(
            "It is not allowed to add sales for previous years after adding sales for current year."
          );
        }
        if (
          latestData &&
          preYear === latestData?.year &&
          preMonth < latestData?.month
        ) {
          setMonth("");
          return customToast.error(
            "It is not allowed to add sales for previous months after adding sales for current month."
          );
        }
      } catch (err) {
        console.log(err.response);
        if (err?.response?.data?.message)
          customToast.error("hellooooooooooooooooo");
        else customToast.error("Sorry Something Went Wrong");
        setMonth("");
      }
    };
    if (month && year && stk && headquarter) {
      fetchData();
    }
  }, [month, year, stk, headquarter, secondary]);

  const stkSelect = useMemo(() => {
    return stockists?.data?.filter((el) => headquarter?._id === el?.city?._id);
  }, [headquarter, stockists?.data]);

  const stkOptions = useMemo(
    () => generateSelectData(stkSelect, "businessName"),
    [stkSelect]
  );

  const hqOptions = useMemo(
    () => generateSelectData(headquarters?.data, "name"),
    [headquarters]
  );

  const divisionSelect = useMemo(
    () => generateSelectData(divisionData, "name"),
    [divisionData]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await viewProductV1({divisionId: division ? division.value : loggedIn?.user?.division});
        setProdArray(res?.data);
      } catch (error) {
        customToast.error("Error occurred while fetching products");
        console.log(error);
      }
    };
    fetchData();
  }, [division]);
  const currentMonth = moment.tz(new Date(), "Asia/Kolkata").month();

  const filtereMonthOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    if (year?.label == currentYear && secondary !== "true") {
      return monthOptions.filter((el, idx) => el.value < currentMonth);
    } else return monthOptions;
  }, [currentMonth, secondary, year?.label]);

  const exportProducts = useCallback(
    (e) => {
      e.preventDefault();
      if (loggedIn?.user?.des === 101 && !division)
        return customToast.error("Please select division !");
      let products = [];
      if (loggedIn?.user?.des === 101) {
        products = prodArray?.filter(
          (e) => division?.value === e?.division?._id
        );
      } else products = prodArray;
      const data = products?.map((item, idx) => {
        const obj = {};
        obj.productName = item.name;
        obj.uid = item.uid;
        obj.ptr = item.ptr;
        obj.pts = item.pts;
        obj.mrp = item.mrp;
        obj.openingBalanceQty = "";
        obj.receivedQuantity = "";
        obj.salesQuantity = "";
        obj.freeStocks = "";

        return obj;
      });

      data?.forEach((item) => {
        prevSales?.forEach((el) => {
          if (item?.productName === el?.product?.name) {
            item.openingBalanceQty = el?.openingBalanceQty;
            item.receivedQuantity = el?.receivedQuantity;
            item.salesQuantity = el?.salesQuantity;
            item.freeStocks = el?.freeStocks;
          }
        });
      });

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "UploadSSFormat.xlsx");
    },
    [prodArray, prevSales, division, loggedIn]
  );

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      if (file) fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e?.target?.result;

        const workbook = XLSX.read(bufferArray, { type: "buffer" });
        const wsName = workbook.SheetNames[0];
        const ws = workbook.Sheets[wsName];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      if (data?.length === 0) {
        inputRef.current.value = null;
        return customToast.error("Please fill data in excel sheet !");
      }

      const filterData = [];

      data?.forEach((item) => {
        prodArray?.forEach((el) => {
          if (
            item?.productName === el?.name &&
            item?.openingBalanceQty !== "" &&
            item?.openingBalanceQty !== undefined &&
            item?.salesQuantity !== "" &&
            item?.salesQuantity !== undefined &&
            item?.receivedQuantity !== "" &&
            item?.receivedQuantity !== undefined &&
            item?.freeStocks !== "" &&
            item?.freeStocks !== undefined
          ) {
            let obj = {
              product: el,
              openingBalanceQty: item?.openingBalanceQty,
              receivedQuantity: item?.receivedQuantity,
              salesQuantity: item?.salesQuantity,
              freeStocks: item?.freeStocks,
            };
            filterData.push(obj);
          }
        });
      });

      setUploadData(filterData);
    });

    promise.catch((error) => {
      console.log(error);
      return customToast.error("Error Occurred !");
    });
  };

  const getPrice = useCallback(
    (rowIdx, data) => {
      let costLabel = priceHeader[rowIdx] ? priceHeader[rowIdx] : priceHeader;
      let price =
        costLabel?.label === "PTS"
          ? data?.pts
          : costLabel?.label === "PTR"
          ? data?.ptr
          : costLabel?.label === "MRP"
          ? data?.mrp
          : costLabel?.label === "CUSTOM"
          ? customPrices[rowIdx]
          : 0;
      return Number(price);
    },
    [priceHeader, customPrices]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "product.name",
      },
      {
        Header: "Price",
        accessor: "pts",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 100,
        maxWidth: 120,
        Cell: (props) => {
          let data = props?.row?.original?.product;
          let rowIdx = props?.row?.original?.sr;
          let costLabel = priceHeader[rowIdx]
            ? priceHeader[rowIdx]
            : priceHeader;
          let price = getPrice(rowIdx, data);

          useEffect(() => {
            setProdPrice((prev) => ({
              ...prev,
              [rowIdx]: Number(price),
            }));
          }, [priceHeader, editPrice]);

          return (
            <div>
              <Select
                value={priceHeader[rowIdx] ? priceHeader[rowIdx] : priceHeader}
                options={dropdownOptions}
                styles={headerSelectStyles}
                components={{
                  IndicatorsContainer: () => <RiArrowDropDownLine size={25} />,
                }}
                onChange={(e) => {
                  if (e?.label === "CUSTOM") {
                    setEditPrice((prev) => ({
                      ...prev,
                      [rowIdx]: true,
                    }));
                  }
                  setPriceHeader((prev) => ({
                    ...prev,
                    [rowIdx]: e,
                  }));
                }}
              />
              {costLabel?.label === "CUSTOM" && editPrice[rowIdx] ? (
                <div>
                  <input
                    type="number"
                    className="sales-table__input h-25"
                    onChange={(e) => {
                      setCustomPrices((prev) => ({
                        ...prev,
                        [rowIdx]: e?.target?.value,
                      }));
                    }}
                  />
                  <IoMdSave
                    className="icon-color-green cursor-pointer"
                    size={20}
                    onClick={() => {
                      setEditPrice((prev) => ({
                        ...prev,
                        [rowIdx]: false,
                      }));
                    }}
                  />
                </div>
              ) : (
                <div>{price}</div>
              )}
            </div>
          );
        },
      },
      {
        Header: (
          <OverlayTrigger
            className="cursor-pointer"
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-$top`} style={{ fontSize: "1.3rem" }}>
                Previous months Closing Quantity is this month's Opening Balance
              </Tooltip>
            }
          >
            <span>
              Opening Balance Qty{" "}
              <FaCircleExclamation className="tp__activity-types-icon-5" />
            </span>
          </OverlayTrigger>
        ),
        accessor: "openingBalanceQty",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        maxWidth: 90,
      },
      {
        Header: (
          <OverlayTrigger
            className="cursor-pointer"
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-$top`} style={{ fontSize: "1.3rem" }}>
                Quantity of Selected Month's Primary Sales is this Months
                Received Quantity
              </Tooltip>
            }
          >
            <span>
              Received Qty{" "}
              <FaCircleExclamation className="tp__activity-types-icon-5" />
            </span>
          </OverlayTrigger>
        ),
        accessor: "receivedQuantity",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        maxWidth: 90,
      },
      {
        Header: "Total Quantity",
        accessor: "",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          let data = props?.row?.original;
          return (
            <div>
              {Number(data?.openingBalanceQty) + Number(data?.receivedQuantity)}
            </div>
          );
        },
      },
      {
        Header: "Total Value",
        accessor: "",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        // maxWidth: 200,
        Cell: (props) => {
          let data = props?.row?.original;
          let rowIdx = data?.sr;
          let price = getPrice(rowIdx, data?.product);
          let totalQt =
            Number(data?.openingBalanceQty) + Number(data?.receivedQuantity);
          let value = (Number(totalQt) * Number(price)).toFixed(2);

          useEffect(() => {
            setTotalValue((prev) => ({
              ...prev,
              [rowIdx]: Number(value).toFixed(2),
            }));
          }, [priceHeader]);

          return <div>{`₹ ${value}`}</div>;
        },
      },
      {
        Header: "Sales Quantity",
        accessor: "salesQuantity",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        maxWidth: 90,
      },
      {
        Header: "Free Stocks",
        accessor: "freeStocks",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        maxWidth: 90,
      },
      {
        Header: "Sales Value",
        accessor: "",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          let data = props?.row?.original;
          let rowIdx = data?.sr;
          let price = getPrice(rowIdx, data?.product);

          let value = Number(price) * Number(data?.salesQuantity);
          useEffect(() => {
            setSalesValue((prev) => ({
              ...prev,
              [rowIdx]: Number(value).toFixed(2),
            }));
          }, [priceHeader, customPrices]);

          return <div>{`₹ ${value}`}</div>;
        },
      },
      {
        Header: "Closing Quantity",
        accessor: "",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        maxWidth: 90,
        Cell: (props) => {
          let data = props?.row?.original;
          let value =
            Number(data?.openingBalanceQty) +
            Number(data?.receivedQuantity) -
            Number(data?.salesQuantity) -
            Number(data?.freeStocks);
          return <div>{value}</div>;
        },
      },
      {
        Header: "Closing Value",
        accessor: "",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 80,
        // maxWidth: 90,
        Cell: (props) => {
          let data = props?.row?.original;
          let rowIdx = data?.sr;
          let price = getPrice(rowIdx, data?.product);
          let closingQt =
            Number(data?.openingBalanceQty) +
            Number(data?.receivedQuantity) -
            Number(data?.salesQuantity) -
            Number(data?.freeStocks);
          let value = (Number(price) * Number(closingQt)).toFixed(2);

          useEffect(() => {
            setClosingValue((prev) => ({
              ...prev,
              [rowIdx]: value,
            }));
          }, [priceHeader]);

          return <div>{`₹ ${value}`}</div>;
        },
      },
    ],
    [priceHeader, editPrice]
  );

  const resetForm = () => {
    inputRef.current.value = null;
    setUploadData([]);
    setstk(null);
    setHeadquarter(null);
    setMonth(null);
    setYear(null);
  };

  const tableData = useMemo(() => addIndex(uploadData), [uploadData]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (loggedIn?.user?.des === 101 && !division)
        return customToast.error(
          "Please download the new format and try again !"
        );
      if (
        !month ||
        !year ||
        !headquarter ||
        !stk ||
        inputRef.current.value === null
      ) {
        return customToast.error("Please fill all the required fields !");
      }

      try {
        let arr = [];

        tableData?.forEach((item) => {
          let obj = {
            product: item?.product,
            openingBalanceQty: Number(item?.openingBalanceQty),
            receivedQuantity: Number(item?.receivedQuantity),
            salesQuantity: Number(item?.salesQuantity),
            freeStocks: Number(item?.freeStocks),
            price: Number(prodPrice[item?.sr]),
            priceType: priceHeader[item?.sr]
              ? priceHeader[item?.sr]?.value
              : priceHeader?.value,
            totalQuantity:
              Number(item?.openingBalanceQty) + Number(item?.receivedQuantity),
            totalValue: Number(totalValue[item?.sr]),
            salesValue: Number(salesValue[item?.sr]),
            closingQuantity: Number(
              item?.openingBalanceQty +
                item?.receivedQuantity -
                item?.salesQuantity -
                item?.freeStocks
            ),
            closingValue: Number(closingValue[item?.sr]),
          };
          arr.push(obj);
        });

        const payload = {
          month: month?.value,
          year: year?.label,
          headquarter: headquarter?._id,
          stockist: stk?._id,
          table: arr,
          divisionId: division ? division.value : loggedIn?.user?.division,
          date: new Date(),
          images: "",
        };

        const res = await addSecondarySales(payload);
        customToast.success("Secondary sales added successfully !");
        resetForm();
      } catch (error) {
        console.log(error);
        customToast.error("Error occurred !");
      }
    },
    [
      month,
      headquarter,
      year,
      stk,
      tableData,
      priceHeader,
      prodPrice,
      customPrices,
      closingValue,
      totalValue,
      salesValue,
    ]
  );

  return (
    <div className="main-content admin-content px-5">
      <div className="area-creation-content rounded-3">
        <section className="admin-creation-content__heading">
          <MdArrowBackIos
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <h2 className="web-app__heading">Upload Secondary Sales</h2>
          <a
            href="#"
            className="ms-auto"
            style={{ textDecoration: "none", color: "var(--color-tertiary)" }}
          >
            How to upload ?
          </a>
        </section>

        <section>
          <div>
            <div className="primarysales-filter">
             {loggedIn?.user?.des === 101 && (
                <div className="util-tp-filter">
                  <p className="mb-2">
                    Select Division <span className="asterisk-imp">*</span>
                  </p>
                  <Select
                    name="division"
                    id="division"
                    value={division}
                    options={divisionSelect}
                    onChange={({ value, label }) => {
                      setUploadData([]);
                      setDivision({ value, label });
                    }}
                    styles={adminStyles}
                    placeholder="Select Division"
                  />
                </div>
              )}

              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Year <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={adminStyles}
                  options={yearOptions}
                  onChange={(e) => setYear(e)}
                  value={year}
                  placeholder="Select Year"
                />
              </div>
              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Month <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={adminStyles}
                  options={filtereMonthOptions}
                  onChange={(e) => setMonth(e)}
                  value={month}
                  placeholder="Select Month"
                />
              </div>
              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Headquarter <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={adminStyles}
                  options={hqOptions}
                  onChange={(e) => setHeadquarter(e)}
                  value={headquarter}
                  placeholder="Select Headquarter"
                />
              </div>
              <div className="util-tp-filter">
                <p className="mb-2">
                  Select Stockist <span className="asterisk-imp">*</span>
                </p>
                <Select
                  styles={adminStyles}
                  options={stkOptions}
                  onChange={(e) => setstk(e)}
                  value={stk}
                  placeholder="Select Stockist"
                />
              </div>


              {/* {month && year && stk && headquarter && division &&( */}
                <>
                  <div className="util-tp-filter">
                    <p className="mb-2">Upload File</p>
                    <input
                      ref={inputRef}
                      type="file"
                      placeholder="you can upload image, excel or pdf"
                      onChange={(e) => {
                        setUploadData([]);
                        let file = e?.target?.files[0];
                        readExcel(file);
                      }}
                    />
                  </div>
                  <div className="util-tp-filter">
                    <button
                      onClick={exportProducts}
                      className="button-blue-gradient ps-auto mt-5"
                      // style={{maxHeight:"55px"}}
                    >
                      Download New Format
                    </button>
                  </div>
                  
                </>
              {/* )} */}
            </div>
          </div>
        </section>
      </div>
      <div className="filter-table">
        <Table columns={columns} data={tableData} />
      </div>

      {tableData?.length > 0 && (
        <div className="d-flex mb-5 justify-content-between">
          <button className="button-blue-gradient" onClick={handleSubmit}>
            Upload Data
          </button>
          <button className="button-red-gradient" onClick={resetForm}>
            Cancel Upload
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadSecondarySales;
