import { getGeoLocationApprovalData, updateApprovalStatus } from "../services/geoFencing";
import { approveCount } from "./approveCountReducer";


const initialState = {
    data :[]
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case "VIEW_DATA":
            return action.payload;

        case "UPDATE_STATUS":
            return {data: state.data}
            
        default:
            return state;    
    }
};

export const GetGeoLocationData = (details) => {
    return async(dispatch) => {
        const {data} = await getGeoLocationApprovalData(details);

        dispatch({
            type: "VIEW_DATA",
            payload : {data: data}
        });
    };
};

export const UpdateStatusApproval = (details, type) => {
    return async (dispatch) => {
        try {
            const { data } = await updateApprovalStatus(details, type);   

            dispatch({
                type: "UPDATE_STATUS",
                payload: { data : data}  
            });

            dispatch(GetGeoLocationData(details));
            dispatch(approveCount())
        } catch (error) {
            console.log(error);
        }
    };
};

export default reducer;


// NOTE : this file is not for use, need to make some changes 