import { useEffect, useState, useMemo, Suspense, lazy } from "react";
import StyledCalendar from "../../../../components/Calendar";
import customToast from "../../../../components/CustomToast";
import Table from "../../../../components/Table";
import { addLeaveRequest, viewLeaves ,deleteLeaveRequest} from "../../../../services/leaveRequest";
import {
  formatDate,
  monthOptions,
  yearOptions,
} from "../../../../utils/helper";
import serialise, {
  generateSelectUser,
} from "../../../../utils/serialiseResponse";
import { dcrStyles, selectUserStyles } from "../../../public/stylesheets/selectStyles";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { viewUsersForUtilities } from "../../../../services/utilities";
import { MdCheckCircleOutline, MdDelete, MdDeleteOutline, MdOutlineGroup } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import moment from "moment-timezone";
import { clearUsers, setUsersForUtilities } from "../../../../reducers/users/employeeReducer";
const DatePickers = lazy(() => import("../../../../components/DatePicker"))

const LeaveForm = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const employees = useSelector(({ employee }) => employee);

  const [ranges, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [leaveReason, setLeavereason] = useState("");
  const [leaveData, setLeaveData] = useState([]);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  // const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState({label:loggedIn.user?.firstName + " " + loggedIn.user?.lastName, value:loggedIn.user?._id, des:loggedIn.user?.des});
  const [selectedUserForLeave, setSelectedUserForLeave] = useState({label:loggedIn.user?.firstName + " " + loggedIn.user?.lastName, value:loggedIn.user?._id, des:loggedIn.user?.des});

  const [selectedLeaveRequest,setSelectedLeaveRequest] = useState();

  const [showEdit,setShowEdit] = useState(false);

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
  

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        className="react-table-select"
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option === 1 ? "Approved" : option === 0 ? "Pending" : "Rejected"}
          </option>
        ))}
      </select>
    );
  }

  useEffect(() => {
    let filter = {
      selectedUser: selectedUser?.value,
      month: month,
      year: Number(year),
    };

    viewLeaves(filter).then((response) => setLeaveData(response.data));
  }, [month, year, selectedUser]);

  const EditPopup = () => {
  

    const handleDelete = (e) => {
      e.preventDefault();
      
      deleteLeaveRequest(selectedLeaveRequest).then(()=>{
        customToast.success("Leave Request deleted successfully ");
       
        let filter = {
          selectedUser: selectedUser?.value,
          month: month,
          year: Number(year),
        };
    
        viewLeaves(filter).then((response) => setLeaveData(response.data));
      }).catch(e=>{
        customToast.error("It seems we encountered and error!");
      });
      setShowEdit(false);
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Leave Request</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form 
          onSubmit={handleDelete}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                Are you Sure You Want To "PERMANENTLY DELETE" This Leave Request
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Start Date",
        accessor: "startDate",
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        Cell: (props) => {
          return formatDate(props.row.original.startDate);
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        Cell: (props) => {
          return formatDate(props.row.original.endDate);
        },
      },
      {
        Header: "Reason for Leave",
        accessor: "leaveReason",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Status",
        accessor: "isApproved",
        Cell: (props) => {
          return props.row.original?.isApproved === 1 ? (
            <span className="approved-text">Approved</span>
          ) : props.row.original?.isApproved === 0 ? (
            <span className="pending-text">Pending</span>
          ) : (
            <span className="rejected-text">Rejected</span>
          );
        },
        disableFilters: false,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Delete",
        accessor: "delete",
        Cell: (props) => {
          return (
            <button
              disabled={loggedIn?.user?.isAdmin?false:true}
              className="react-table-view-link bg-transparent border-0"
              onClick={() => {
                setShowEdit(true);
                setSelectedLeaveRequest(props?.row?.original?._id);
              }}
            >
              <MdDelete className={`icon-color-error ${loggedIn?.user?.isAdmin?null:"opacity-25"}`} />
            </button>
          );
        },
        minWidth: 60,
        maxWidth: 65,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const tableData = useMemo(() => serialise(leaveData), [leaveData]);

  const resetForm = () => {
    setRanges([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setLeavereason("");
  };

  const handleChange = async (e) => {
    setLeavereason(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!ranges){
      customToast.error("Please Select the Date");
      return
    }
    else if(!leaveReason){
      customToast.error("Please enter the Leave Reason");
      return
    } 

    const data = {
      startDate: ranges[0].startDate,
      endDate: ranges[0].endDate,
      leaveReason: leaveReason,
      userId: selectedUserForLeave ? selectedUserForLeave?.value : loggedIn?.user?._id,
      bySenior: selectedUser?.value ? true : false
    };


    try {
      await addLeaveRequest(data);
      customToast.success("Leave Request SuccessFully Submitted");
      const filter = {
        selectedUser: selectedUser?.value,
        month: month,
        year: Number(year),
      };
      const response = await viewLeaves(filter);
      setLeaveData(response.data);
      resetForm();
    } catch (e) {
      e?.response?.status === 409 ? customToast.error(e?.response?.data?.message)
       : customToast.error("We Encountered an Error");
      resetForm();
    }
  };

  // useEffect(() => {
  //   try {
  //     viewUsersForUtilities().then((res) => {
  //       setEmployees([...res.data]);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  useEffect(() => {
          dispatch(setUsersForUtilities({ includeMe: true }))
          return () => {
            dispatch(clearUsers());
          };
        }, [dispatch]);


  return (
    <>
      <form className="leave-content" onSubmit={handleSubmit}>
        <h1 className="web-app__heading">Leave Request</h1>
        <div className="row mt-4">
          <div className="calendar-container col-lg-6">
            <StyledCalendar
              blockPreviousDate = {true}
              user = {loggedIn?.user}
              ranges={ranges}
              onChange={({ selection }) => setRanges([selection])}
            />
          </div>

          <div className="col-lg-6">
            <div className="leave-content__reason">
            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
                <div className="util-tp-filter">
                <p className="util-select-user__label">Select User</p>
                <div className="d-flex flex-row">
                  <MdOutlineGroup className="icon-color-green mt-4 me-2" size={25}/>
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUserForLeave}
                    onChange={(e) => setSelectedUserForLeave({ ...e })}
                  />
                  <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{cursor:"pointer"}} onClick={() => setSelectedUserForLeave(null)}/>
                </div>
              </div>
            ) : null}
              <label htmlFor="leave-reason" style={{marginTop:"20px"}}>Reason for leave <span style={{color:"#e74c3caa"}} >*</span> </label>
              <textarea
              style={{height:"150px"}}
                value={leaveReason}
                onChange={handleChange}
                name="leave-reason"
                id="leave-reason"
                cols="30"
                rows="8"
                placeholder="Enter Reason for Leave"
              ></textarea>
              <button className="button-submit" type="submit">
                Submit
              </button>
            </div>
            
          </div>
        </div>
      </form>

      <hr className="leave-hr" />

      <section className="leave-table">
      <form >
          <div className="util-tp-query">
       
            <div className="util-tp-filter">
              <p className="mb-3">Select Month <span style={{color:"#e74c3caa"}} >*</span></p>
              <Suspense>
                <DatePickers
                  placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                  setMonth={setMonth}
                  setYear={setYear}
                />
              </Suspense>
            </div>

            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
                <div className="util-tp-filter">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                </div>
              </div>
            ) : null}


          </div>
        </form>

        <div className="filter-table">
          {/* <MaterialTable
            options={{
              exportButton: true,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
            columns={columnData}
            data={leaveData}
            title="Leave Requests"
          /> */}
          <Table columns={columns} data={tableData} />
        </div>
      </section>
      {showEdit && <EditPopup />}
    </>
  );
};

export default LeaveForm;
