import React, { useState, useEffect } from 'react';

const QuantitySelector = ({
  initialValue = 1,
  minValue = 1,
  maxValue = Infinity,
  onQuantityChange = () => { },
}) => {
  const [quantity, setQuantity] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue.toString());

  useEffect(() => {
    setQuantity(initialValue);
    setInputValue(initialValue.toString());
  }, [initialValue]);

  const increment = () => {
    setQuantity(prev => {
      const newValue = prev < maxValue ? prev + 1 : prev;
      setInputValue(newValue.toString());
      onQuantityChange(newValue);
      return newValue;
    });
  };

  const decrement = () => {
    setQuantity(prev => {
      const newValue = prev > minValue ? prev - 1 : prev;
      setInputValue(newValue.toString());
      onQuantityChange(newValue);
      return newValue;
    });
  };

  const handleEditStart = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    const newValue = parseInt(inputValue, 10);
    if (!isNaN(newValue) && newValue >= minValue && newValue <= maxValue) {
      setQuantity(newValue);
      onQuantityChange(newValue);
    } else {
      setInputValue(quantity.toString());
    }
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  return (
    <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden shadow-sm hover:shadow w-full max-w-xs transition-shadow duration-300">
      <button
        onClick={decrement}
        disabled={quantity <= minValue}
        className={`flex items-center justify-center px-3 py-2 h-full ${quantity <= minValue ? 'bg-gray-50 text-gray-300' : 'bg-gray-100 hover:bg-gray-200 active:bg-gray-300 text-gray-700'} text-xl font-medium transition-colors duration-200`}
        aria-label="Decrease quantity"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </button>

      {isEditing ? (
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
          className="flex-1 text-center py-2 focus:outline-none focus:bg-blue-50 transition-colors duration-200"
          autoFocus
          aria-label="Edit quantity"
        />
      ) : (
        <div
          onClick={handleEditStart}
          className="flex-1 text-center py-2 cursor-pointer hover:bg-gray-50 font-medium"
        >
          {quantity}
        </div>
      )}

      <button
        onClick={increment}
        disabled={quantity >= maxValue}
        className={`flex items-center justify-center px-3 py-2 h-full ${quantity >= maxValue ? 'bg-gray-50 text-gray-300' : 'bg-gray-100 hover:bg-gray-200 active:bg-gray-300 text-gray-700'} text-xl font-medium transition-colors duration-200`}
        aria-label="Increase quantity"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </button>
    </div>
  );
};

export default QuantitySelector;