import { useEffect, useState, useMemo } from "react";
import { viewTargets } from "../../../../../services/targets";
import serialise, {
  generateSelectData,
  generateSelectUser,
} from "../../../../../utils/serialiseResponse";
import Table from "../../../../../components/Table";
import { MdArrowBackIosNew, MdOutlineGroup, MdOutlineRefresh } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { viewUsersForUtilities } from "../../../../../services/utilities";
import { useSelector } from "react-redux";
import { selectUserStyles } from "../../../../public/stylesheets/selectStyles";
import { FaEye } from "react-icons/fa";
import ListExport from "../../../../../components/ListExport";
import Loader from "../../../../../components/Loader";

const StockistsList = () => {
  const navigate = useNavigate();
  const [stockists, setStockists] = useState([]);
  const loggedIn = useSelector(({ user }) => user);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [showMyList, setShowMyList] = useState(false);
  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();

  const employeeSelect = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );
  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );
  const [lodingState, setLodingState] = useState(false);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      })
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        minWidth: 85,
        maxWidth: 85,
        Cell:(props) =>{
          const address = props?.row?.original?.address;
          if(!address){
            return null;
          }
          const words = (props?.row?.original?.address).split(' ');
          const truncateWords = words.slice(0,4).join(' ');

          return truncateWords;
        }
      },
      {
        Header: "Contact",
        accessor: "stockistContact",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Working Area",
        accessor: "workingArea",
        minWidth: 85,
        maxWidth: 85,
        wordWrap:'break-word',
        Cell: ({ value }) => value || "NA",
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
        maxWidth: 40,
        minWidth: 40,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    async function asyncFetch() {
      let data = {
        selectedUser: selectedUser?.value,
        showApproved:true,
        dcsFilter: showMyList ? 'MyList' : ''
      };
      
      setLodingState(true)
      let res = await viewTargets("stockist", data);
      setLodingState(false)
      setStockists(res.data);
    }

    asyncFetch();
  }, [selectedUser, showMyList]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const tableData = useMemo(() => serialise(stockists), [stockists]);
  
  return (
    <main className="main-content admin-content">
      {lodingState ? <Loader loadingMsg="Please Wait it may take more than 1 min" /> : (
      <div className="area-creation-content">
        {/* <section className="admin-creation-content__heading">
          <MdArrowBackIosNew
            className="admin-creation-content__back-button"
            onClick={() => navigate("/utilities")}
          />
          <h2 className="web-app__heading">Stockists List</h2>
        </section> */}
        <section className="admin-creation-content__heading justify-content-between py-2">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              className="admin-creation-content__back-button"
              onClick={() => navigate("/utilities")}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Stockists List
            </span>
          </h2>
          <div className="d-flex gap-4 align-items-center pt-4" >
            <div className="form__controll " style={{ width: "fit-content", color: "#c7c4e9" }}>
              <span className="">Individual List</span>
              <label className="toggle-label2 mx-3" >
                <input
                  type="checkbox"
                  checked={showMyList}
                  onClick={() => {
                    setShowMyList(!showMyList);
                    // setSelectedUser(null);
                  }}
                />
                <span />
              </label>
            </div>
          </div>
        </section>
        {loggedIn?.user?.des < 121 ||
        loggedIn?.user?.isAdmin ? (
          <div className="util-list-filters">
            <div className="util-tp-filter">
              <p className="util-select-user__label">Select User</p>
              <div className="util-select-user">
                <MdOutlineGroup className="util-select-user__icon" />
                <Select
                  styles={selectUserStyles}
                  options={employeeSelect}
                  placeholder="Select User"
                  className="mt-3"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser({ ...e })}
                />
                <MdOutlineRefresh size={25} className="icon-color-tertiary mt-4 ms-2" style={{cursor:"pointer"}} onClick={() => setSelectedUser(null)}/>
              </div>
            </div>
          </div>
        ) : null}
        <section
          className="area-creation-content__info"
          style={{ marginTop: "-4rem" }}
        >
          <div className="filter-table">
            {stockists.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({stockists.length}) Entries
              </h2>
            )}
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} exportExcel={false} callback={ListExport} empl={selectedUser ? employeeDataForExcel : loggedIn?.user} fileSrc="Stockist List"/>
              ) : (
              <h3 className="no-results-message">No results found</h3>
            )}
          </div>
        </section>
      </div>
      )}
    </main>
  );
};

export default StockistsList;
