import { countApproval } from "../services/notifications";

const initialState = {
    data: {}
};

const approvalCountReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_COUNTS":
        return { ...state, data: action.payload };
  
      default:
        return state;
    }
}
export const approveCount = () => {
  return async (dispatch) => {
    try {
      const data = await countApproval();
      dispatch({
        type: "SET_COUNTS",
        payload: data.data,
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export default approvalCountReducer;