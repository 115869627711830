import customToast from "../../components/CustomToast/index.js";
import {
  newLocation,
  viewLocations,
  deleteLocation,
  editLocation,
  viewAllLocation,
  viewLocationsForApproval,
  approveLocation,
  rejectLocation,
  newLocationForApproval,
  fetchLocationV1,
  editLocationV1,
  deleteLocationV1,
  addLocationV1,
} from "../../services/locations.js";
import { approveCount } from "../approveCountReducer.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION_CITY":
      return action.payload;

    case "SET_LOCATION_CITY_All":
      return action.payload;

    case "SET_LOCATION_FOR_APPROVAL":
      return action.payload;

    case "CREATE_LOCATION_CITY":
      return { data: [...state.data, action.payload.data.data] };

    case "CREATE_LOCATION_CITY_FOR_APPROVAL":
      return { data: [...state.data, action.payload.data.data] };

    case "EDIT_LOCATION_CITY":
      return {data:state.data}

    case "CLEAR_LOCATION_CITY":
      return initialState;

    case "DELETE_LOCATION_CITY":
      // return action.payload;
      return {data:state.data};

    case "APPROVE_CITY":
      return {data:state.data};

    case "REJECT_CITY":
      return {data:state.data};

    default:
      return state;
  }
};

export const setCities = (headquarterId, type, isStatus) => {
  return async (dispatch) => {
    // const { data } = await viewLocations("city", headquarterId, type);
    const { data } = await fetchLocationV1("city", headquarterId, type, isStatus);

    dispatch({
      type: "SET_LOCATION_CITY",
      payload: { data: data },
    });
  };
};

export const setCreatedCities = (filter) => {
  return async (dispatch) => {
    const { data } = await viewAllLocation("city", filter);

    dispatch({
      type: "SET_LOCATION_CITY_All",
      payload: { data: data },
    });
  };
};

export const setCitiesForApproval = (filters) => {
  return async (dispatch) => {
    const { data } = await viewLocationsForApproval("city", filters);

    dispatch({
      type: "SET_LOCATION_FOR_APPROVAL",
      payload: { data: data },
    });
    dispatch(approveCount())
  };
};

export const addCity = (data) => {
  return async (dispatch) => {
    try {
      // data = await newLocation("city", data);
      data = await addLocationV1("city", data);
    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "CREATE_LOCATION_CITY",
      payload: { data: data },
    });
  };
};

export const addCityForApproval = (data) => {
  return async (dispatch) => {
    try {
      data = await newLocationForApproval(data);

    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "CREATE_LOCATION_CITY_FOR_APPROVAL",
      payload: { data: data },
    });
  };
};

export const editCities = (id, data, areaType) => {
  return async (dispatch) => {
    try {
      // data = await editLocation("city", id, data);
      data = await editLocationV1("city", id, data, areaType);
    } catch (e) {
      if (e) throw e;
    }

    dispatch({
      type: "EDIT_LOCATION_CITY",
      payload: { data: data },
    });
    dispatch(setCities("", areaType));
  };
};

export const clearCities = () => {
  return {
    type: "CLEAR_LOCATION_CITY",
  };
};

export const deleteCities = (data, areaType) => {
  return async (dispatch) => {
    try {
      // data = await deleteLocation("city", data);
      data = await deleteLocationV1("city", data);
    } catch (e) {
      if (data.error) throw data.error;
    }
    dispatch({
      type: "DELETE_LOCATION_CITY",
      payload: { data: data },
    });
    dispatch(setCities("", areaType));
  };
};

/*  Approve / Reject */

export const approveCity = (id, data) => {
  return async (dispatch) => {
    try {
      data = await approveLocation("city", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_CITY",
      payload: { data: data },
    });
    dispatch(setCitiesForApproval());
  };
};

export const rejectCity = (id, data) => {
  return async (dispatch) => {
    try {
      data = await rejectLocation("city", id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_CITY",
      payload: { data: data },
    });
    dispatch(setCitiesForApproval());
  };
};

export default reducer;
