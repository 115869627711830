import moment from "moment-timezone";

const initialState = {
  headquarter: "", // we are going to keep the states same around pages reason if user last time selected a headquarter then on another page he might want to see data for same headquarter
  state: "",
  stockist: "",
  city: "",
  month: moment().month(),
  year: moment().year(),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INPUT_VALUE":
      return { ...state, [action.payload.key]: action.payload.value };
    case "CLEAR_INPUT_VALUE_STATES":
      return initialState;
    default:
      return state;
  }
};

export const setInputValue = (key, value) => {
  return {
    type: "SET_INPUT_VALUE",
    payload: { key, value },
  };
};

export const clearInputValueStates = () => {
  return {
    type: "CLEAR_INPUT_VALUE_STATES",
  };
};

export default reducer;
