import { Outlet } from "react-router-dom";
import AdminNav from "../AdminSidenav";
import { title, links } from "./navData.js";

import "../../public/stylesheets/admin-locations.css";
import { viewCallReport, viewCallReportForApproval } from "../../../services/dcr";
import { viewTargetsForApproval } from "../../../services/targets";
import { viewLocationsForApproval } from "../../../services/locations";
import { getExpenseForApproval } from "../../../services/expenses";
import { countApproval, countLR, viewLeavesForApproval } from "../../../services/notifications";
import { viewTPForApproval, viewTPWithStatus } from "../../../services/tourProgram";
import { viewSSForApproval, viewPSForApproval } from "../../../services/sales";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { viewGiftsForapproval, viewSampleForApproval } from "../../../services/reports.js";
import { getAllDeletionRequest } from "../../../services/deletionRequest.js";
import { getGeoLocationApprovalData } from "../../../services/geoFencing.js";
import { viewCallPlanningForApproval } from "../../../services/callPlanning.js";
import { approveCount } from "../../../reducers/approveCountReducer";
const Approvals = () => {
  const loggedIn = useSelector(({ user }) => user);
  const [data, setData] = useState([]);
  let approvalLength = useSelector(state=>state.approvalLength.trigger)
  

  // const callReports = async () => {
  //   return await viewCallReportForApproval({ viewForApproval: true });
  // };
  // // const chemists = async () => {
  // //   return await viewTargetsForApproval("chemist");
  // // };
  // const city = async () => {
  //   return await viewLocationsForApproval("city");
  // };
  // const doctors = async () => {
  //   return await viewTargetsForApproval("doctor");
  // };
  // // const expense = async () => {
  // //   return await getExpenseForApproval();
  // // };
  // // const leaveRequests = async () => {
  // //   return await viewLeavesForApproval();
  // // };
  // const countLeaveRequests = async () => {
  //   return await countApproval();
  // }
  // const countExpense = async () => {
  //   return await countApproval();
  // }
  // const route = async () => {
  //   return viewLocationsForApproval("route");
  // };
  // const stockists = async () => {
  //   return await viewTargetsForApproval("stockist");
  // };
  // const tp = async () => {
  //   return await viewTPForApproval();
  // };
  // const callPlanningApproval = async () => {
  //   return await viewCallPlanningForApproval();
  // };
  // const secondarySalesForApproval = async ()=>{
  //   return await viewSSForApproval();
  // }
  // const primarySalesForApproval = async ()=>{
  //   return await viewPSForApproval();
  // };
  // const giftsApproval = async ()=>{
  //   return await viewGiftsForapproval();
  // };
  // const samplesApproval = async ()=>{
  //   return await viewSampleForApproval();
  // };
  // const deletionRequest = async ()=>{
  //   return await getAllDeletionRequest();
  // }
  // const geoFencingApproval = async ()=>{
  //   let flag = {
  //     submitterId: loggedIn?.user?._id,
  //     viewForPending: true,
  //   };
  //   return await getGeoLocationApprovalData({ flag: flag });
  // }
  // let apiArray = [
  //   callReports,
  //   tp,
  //   callPlanningApproval,
  //   // expense,
  //   // leaveRequests,
  //   countLeaveRequests,
  //   countExpense,
  //   doctors,
  //   // chemists,
  //   stockists,
  //   city,
  //   route,
  //   secondarySalesForApproval,
  //   primarySalesForApproval,
  //   samplesApproval,
  //   giftsApproval,
  //   geoFencingApproval,
  //   deletionRequest,
  // ];
  // const parallelApiCall = async () => {
  //   let response = await Promise.all([
  //     apiArray[0](),
  //     apiArray[1](),
  //     apiArray[2](),
  //     apiArray[3](),
  //     apiArray[4](),
  //     apiArray[5](),
  //     apiArray[6](),
  //     apiArray[7](),
  //     apiArray[8](),
  //     apiArray[9](),
  //     apiArray[10](),
  //     apiArray[11](),
  //     apiArray[12](),
  //     apiArray[13](),
  //     // apiArray[14](),
  //   ]);
  //   setData(response);
  // };
  const approvalData = useSelector((state) => state.approvalCount.data);
  const dispatch = useDispatch()
 

  const fetchApprovalCounts = async () => {
    const response = await countApproval();
    if (response) {
      const transformed = links.map((link) => {
        const matchingKey = Object.keys(response.data).find(
          (key) => key === link.accessor
        );
        return {
          ...link,
          count: matchingKey ? response.data[matchingKey] : 0,
        };
      });
      setData(transformed);
   
    }
   
  };
  useEffect(() => {
    fetchApprovalCounts();
  }, []);

  // const updateApprovalLength = () =>{
  //  let updatedArray = data.map((e,i)=>{
  //   if(i === approvalLength.index){
  //     return {...e,data:approvalLength.data};
  //   }
  //   return e;
  //  })
  //  setData(updatedArray)
  // }

 
  // useEffect(() => {
  //  updateApprovalLength() 
  // }, [approvalLength]);
  

  // useEffect(() => {
  //   parallelApiCall();
  // }, []);


// const responseKeyToAccessor = (key) => {
//   const map = {
//     callReports: "call-report",
//     callPlanning: "cp",
//     tps: "tp",
//     expenses: "expense",
//     leaveRequest: "lr",
//     chemists: "chemist",
//     doctors:"doctor",
//     stockists: "stockist",
//     cities: "city",
//     routes: "route",
//     secondarySales: "secondary sales",
//     primarySales: "primary sales",
//     preferences: "deletion request",
//     geoFancing :"geo fancing",
//   };
//   return map[key] || key;
// };

useEffect(() => {
  if (approvalData && Object.keys(approvalData).length > 0) {
    const transformed = links.map((link) => {
      const matchingKey = Object.keys(approvalData).find(
        (key) => key === link.accessor
      );
      return {
        ...link,
        count: matchingKey ? approvalData[matchingKey] : 0,
      };
    });

    setData(transformed);
    
  }
}, [approvalData]);


  return (
    <>
      <AdminNav title={title} links={links} response={data}/>

      <Outlet />
    </>
  );
};

export default Approvals;
