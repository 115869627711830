import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../../../../components/PageTitle";
import { getCampaignDetails, setPriority } from "../../../../../services/campaign";
import { useNavigate, useParams } from "react-router-dom";
import { adminStyles } from "../../../../public/stylesheets/selectStyles";
import Select from "react-select";

const DoctorPriority = () => {
    const navigate = useNavigate()
    const { campaignId } = useParams();
    const [doctors, setDoctors] = useState([]);
    const [chemists, setChemists] = useState([])
    const [stockists, setStockists] = useState([])
    const [editingIndex, setEditingIndex] = useState(null);
    const [priorityValues, setPriorityValues] = useState("");
    const [type, setType] = useState({value: "doctor", label: "Doctor" })
    const inputRef = useRef(null); 
    const saveButtonRef = useRef(null);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; 

    const options = [
        { value: "doctor", label: "Doctor" },
        { value: "chemist", label: "Chemist" },
        { value: "stockist", label: "Stockist" },
    ];


    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getCampaignDetails(campaignId);
                setDoctors(data?.doctors || []);
                setChemists(data?.chemists || []);
                setStockists(data?.stockists || []);
              
                
            } catch (error) {
                console.error("Error fetching doctors:", error);
            }
        };
    
        fetchData();
        
    }, [campaignId ]);


    const handleEdit = (index) => {
        const absoluteIndex = (currentPage - 1) * itemsPerPage + index;
        setEditingIndex(index);
        setPriorityValues(absoluteIndex + 1);
        
       
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current && !inputRef.current.contains(event.target) && 
                saveButtonRef.current && !saveButtonRef.current.contains(event.target) 
            ) {
                setEditingIndex(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    const handleSave = (id) => {
        const newPriority = Number(priorityValues);
        if (!newPriority || newPriority < 1) return;
    
        let updatedList = [];
        let listLength = 0;
    
        if (type?.value === "doctor") {
            updatedList = [...doctors];
            listLength = doctors.length;
        } else if (type?.value === "chemist") {
            updatedList = [...chemists];
            listLength = chemists.length;
        } else if (type?.value === "stockist") {
            updatedList = [...stockists];
            listLength = stockists.length;
        } else {
            return; 
        }
    
        
        if (newPriority > listLength) return;
    
        const itemToUpdate = updatedList.find(item => item._id === id);
        updatedList = updatedList.filter(item => item._id !== id);
    
       
        updatedList.splice(newPriority - 1, 0, itemToUpdate);

        // updatedList = updatedList.map((data, index) => ({
        //     ...data,
        //     priority: index + 1,
        // }));
    
        if (type?.value === "doctor") {
            setDoctors(updatedList);
        } else if (type?.value === "chemist") {
            setChemists(updatedList);
        } else if (type?.value === "stockist") {
            setStockists(updatedList);
        }
    
        setEditingIndex(null);
        setPriorityValues("");
        setTimeout(() => setEditingIndex(null), 0);
    };
    
    

    const handleNext = async () => {
        const priorityData = {
            doctors,
            stockists,
            chemists
        };
    
        const response = await setPriority(campaignId, priorityData);
        navigate(`/admin-panel/holidays/campaign/campaign/${campaignId}/add-user`)
    };
    const list =
        type?.value === "doctor" ? doctors :
        type?.value === "chemist" ? chemists :
        type?.value === "stockist" ? stockists : [];

    const totalPages = Math.ceil(list.length / itemsPerPage);
    const paginatedList = list.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));


    return (
        <div className="main-content admin-content">
            <div className="area-creation-content">
                <PageTitle title="Set Doctor Priority" />
                <section className="area-creation-content__form">
                <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="">
                  Select Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  className="me-5"
                  id="selectType"
                  placeholder="Select Type"
                  styles={adminStyles}
                  options={options}
                  value={type}
                  onChange={(selected) => setType(selected)}
                />
              </div>
                </section>
                <section className="area-creation-content__info">
                    {type?.value &&
                       ((type.value === "doctor" && doctors.length > 0) ||
                       (type.value === "chemist" && chemists.length > 0) ||
                       (type.value === "stockist" && stockists.length > 0)) && (
                        <h2 className="web-app__heading mb-4 ms-2">
                            Showing ({type.value === "doctor" ? doctors.length 
                            : type.value === "chemist" ? chemists.length 
                            : stockists.length}) Entries
                       </h2>
                    )}
                    
                    <div className="filter-table">
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th>Priority</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedList.map((person, index) => (
                                    <tr key={person._id}>
                                        <td onClick={() => handleEdit(index)} className="cursor-pointer position-relative">
                                            {/* {editingIndex !== index && index + 1}  */}
                                            {editingIndex !== index && (index + 1 + (currentPage - 1) * itemsPerPage)}
                                            {editingIndex === index && (
                                                <div className="position-absolute d-flex h-50 gap-3 w-50"
                                                    style={{ top: "15px", left: "50%", transform: "translateX(-50%)", zIndex: 1000 }}>
                                                    <input
                                                    type="number"
                                                    className="form-control text-white fs-4 shadow-none border-0"
                                                    style={{ backgroundColor: "#353456" }}
                                                    value={priorityValues}
                                                    onChange={(e) => setPriorityValues(e.target.value)}
                                                    autoFocus
                                                    ref={inputRef}
                                                    />
                                                    <button className="px-2 py-1 text-black border border-white bg-white rounded" ref={saveButtonRef} onClick={() => handleSave(person._id)}>
                                                        Save
                                                    </button>
                                               </div>
                                           )}
                                       </td>
                                       <td>{person.name}</td>
                                   </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                    {totalPages > 1 && (
                        <div className="pagination-controls  mt-4">
                            <button onClick={handlePrevPage} disabled={currentPage === 1} className="pagination-btn rounded  fw-semibold px-3 py-2 ">
                                Previous
                            </button>
                            <span className="mx-4 text-white">Page {currentPage} of {totalPages}</span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages} className="pagination-btn rounded text fw-semibold  px-3 py-2">
                                Next
                            </button>
                        </div>
                    )}
                    <div className="admin-content-submit-button d-flex justify-content-center mt-2">
                        <button onClick={handleNext} type="submit" className="button-blue-gradient">
                            Next Step
                        </button>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default DoctorPriority;



