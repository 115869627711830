// export const apiURL = "http://192.168.1.4:8002/medorn";
// export const apiURL = "https://backend.medorn.com";
// export const apiURL = "http://localhost:8002";
// export const apiURL = "https://backend.medornventures.in"
export const apiURL = "https://development.medornventures.in/medorn";


export const returnMonthFromIndex = (index) => {
  if (index === 0) return "January";
  else if (index === 1) return "February";
  else if (index === 2) return "March";
  else if (index === 3) return "April";
  else if (index === 4) return "May";
  else if (index === 5) return "June";
  else if (index === 6) return "July";
  else if (index === 7) return "August";
  else if (index === 8) return "September";
  else if (index === 9) return "October";
  else if (index === 10) return "November";
  else if (index === 11) return "December";
};

export const monthOptions = [
  {
    value: 0,
    label: "January",
  },
  {
    value: 1,
    label: "February",
  },
  {
    value: 2,
    label: "March",
  },
  {
    value: 3,
    label: "April",
  },
  {
    value: 4,
    label: "May",
  },
  {
    value: 5,
    label: "June",
  },
  {
    value: 6,
    label: "July",
  },
  {
    value: 7,
    label: "August",
  },
  {
    value: 8,
    label: "September",
  },
  {
    value: 9,
    label: "October",
  },
  {
    value: 10,
    label: "November",
  },
  {
    value: 11,
    label: "December",
  },
];

export const yearOptions = [
  { value: 4, label: "2025" },
  { value: 3, label: "2024" },
  { value: 2, label: "2023" },
  { value: 1, label: "2022" },
];

export const activityOptions = [
  // { value: 1, label: "Working" },
  // { value: 2, label: "Half Day Leave" },
  // { value: 3, label: "Half Day Camp" },
  // { value: 4, label: "Weekends" },
  // { value: 5, label: "Work From Home" },
  // { value: 6, label: "Leave" },
  // { value: 7, label: "Meeting" },
  // { value: 8, label: "Conference" },
  // { value: 9, label: "Closing" },
  { value: 1, label: "Working" },
  { value: 2, label: "Half Day" },
  // { value: 3, label: "Leave" },
  { value: 5, label: "Training" },
  { value: 6, label: "Seminar" },
  { value: 7, label: "Transit" },
  { value: 8, label: "Meeting" },
  { value: 9, label: "Camp" },
  { value: 10, label: "Conference" },
  { value: 11, label: "Half Day (Meeting)" },
  { value: 12, label: "Half Day (Field work)" },
];

export const formatDate = (date) => {
  return new Date(date).toLocaleString("en-IN", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const formatTime = (ts) => {
  const date = new Date(ts);
  const enIn = date.toLocaleTimeString("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return enIn;
};
export const getDiscountedPrice = (price, discount) => {
  // the dicount should be in percentage
  if (discount === 0) return Number(price);
  const num = Number(price);
  const dis = Number(discount);
  const ans = num - Number((num * dis) / 100);
  return ans.toFixed(2);
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      const imageObject = {
        name: file.name,
        data: base64String,
      };
      resolve(imageObject);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const fileDownloader = (files) => {
  if (!files) return;
  const extractFileName = (url) => {
    // Extract the file name from the URL
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
  };
  files.map((fileUrl, idx) => {
    // Create a link element
    const link = document.createElement("a");
    link.href = fileUrl;
    const fileName = extractFileName(fileUrl);
    link.download = fileName || "downloaded-file";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  });
};

const currentYear = new Date().getFullYear();

// Generate items array dynamically

// Function to generate months array for a given year
const generateMonths = (year) => {
  return [
    {
      key: `0-${year}`,
      label: `January ${year}`,
    },
    {
      key: `1-${year}`,
      label: `February ${year}`,
    },
    {
      key: `2-${year}`,
      label: `March ${year}`,
    },
    {
      key: `3-${year}`,
      label: `April ${year}`,
    },
    {
      key: `4-${year}`,
      label: `May ${year}`,
    },
    {
      key: `5-${year}`,
      label: `June ${year}`,
    },
    {
      key: `6-${year}`,
      label: `July ${year}`,
    },
    {
      key: `7-${year}`,
      label: `August ${year}`,
    },
    {
      key: `8-${year}`,
      label: `September ${year}`,
    },
    {
      key: `9-${year}`,
      label: `October ${year}`,
    },
    {
      key: `10-${year}`,
      label: `November ${year}`,
    },
    {
      key: `11-${year}`,
      label: `December ${year}`,
    },
  ];
};

export const items = [];
for (let i = 0; i < 2; i++) {
  // Example: Generating for current year and previous year
  const year = currentYear - i;
  items.push({
    key: `${year}`,
    type: i === 0 ? "group" : undefined,
    label: i === 0 ? `${year}` : `${year}`,
    children: generateMonths(year),
  });
}

export const doctorCategoryOptions = [
  {
    value: 1,
    label: "Non-Core (1 Visit/Month)",
  },
  {
    value: 2,
    label: "Core (2 Visits/Month)",
  },
  {
    value: 3,
    label: "SuperCore (3 Visits/Month)",
  },
  {
    value: 4, 
    label: "Ultra-Core (4 Visits/Month)"
  }
];

export const areaTypeOptions = [
  { value: 1, label: "Local" },
  { value: 2, label: "Ex-Station" },
  { value: 3, label: "Out-Station" },
  { value: 4, label: "Out-Ex-Station" },
  { value: 5, label: "Out-Station-Last-Day" }
];

// Returns the start of the given date in UTC (00:00:00.000Z).
// This ensures there are no timezone offset issues.
export const startOfDayUTC = (date) => {
  const d = new Date(date); // Create Date object
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0)).toISOString(); // Convert to UTC start time
};
// Returns the end of the given date in UTC (23:59:59.999Z).
export const endOfDayUTC = (date) => {
  const d = new Date(date); // Create Date object
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999)).toISOString(); // Convert to UTC end time
};