import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { MdClear, MdDoneAll, MdCheckBoxOutlineBlank } from "react-icons/md";
import {ImCheckboxUnchecked, ImCheckboxChecked} from "react-icons/im" ;
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import {
  approveLeave,
  clearLeaves,
  rejectLeave,
  setApprovedLeaves,
} from "../../../../reducers/leaveReducer";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import serialise, { generateSelectUser } from "../../../../utils/serialiseResponse";
import Table from "../../../../components/Table";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdOutlinePendingActions } from "react-icons/md";
import { updateTrigger } from "../../../../reducers/approveLengthReducer";
import Select from "react-select";
import { viewUsersForUtilities } from "../../../../services/utilities";
import {MdOutlineGroup} from "react-icons/md";
import { selectUserStyles } from "../../../../views/public/stylesheets/selectStyles.js";
import { countApproval } from "../../../../services/notifications";


const formatDate = (date) => {
  return new Date(date).toLocaleString("en-IN", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const ApprovalLR = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const leaveInfo = useSelector(({ leave }) => leave);
  const [allSelected, setAllSelected] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [users, setUsers] = useState([])
  const [selectedUser, setselectedUser] = useState(null)

  const userSelect = useMemo(
    () => generateSelectUser(users, "fullName"),
    [users]
  );
  
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setUsers(res?.data);
      });
    } catch (error) {
      customToast.error("error occured Please Try Again");
    }
  }, []);

  const SelectColumnFilter = useCallback(
  ({ column, columns }) =>{
    return (
      <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
        {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
          {allSelected ? (
              <ImCheckboxChecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el,idx)=>{
                  el.original.isSelected = false ;
                });
                setAllSelected(false);
              }}/>
            ):(
              <ImCheckboxUnchecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el,idx)=>{
                  el.original.isSelected = true ;
                });
                setAllSelected(true);
              }}/>
            )
            }
        </span>
        }
      </div>
  );},[allSelected])
  const handleApprove = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Tour Program");
    const payload = {ids:data , type:"bulk"} ;
    await dispatch(approveLeave(data?._id, payload));
    setShowEdit(false);
    setAllSelected(false);
    //since previous implementation requires id so we send 1 because the new implementation handles 
    // it gracefully
  };
  const handleReject = async (data) => {
    if(data.length === 0)return customToast.error("Please select a Tour Program");
    const payload = {ids:data , type:"bulk"} ;
    await dispatch(rejectLeave(data?._id, payload));
    setShowEdit(false);
    setAllSelected(false);
  };
  const tableData = useMemo(() => serialise(leaveInfo.data), [leaveInfo]);
  const EditPopup = () => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
      {/* <section className="edit-popup"> */}
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Confirm Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Do you want to approve the ${data.length} leave requests?`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Start Date",
        accessor: "startDate",
        disableFilters: true,
        Cell: (props) => (
          <span>{formatDate(props?.row?.original?.startDate)}</span>
        ),
      },
      {
        Header: "End Date",
        accessor: "endDate",
        disableFilters: true,
        Cell: (props) => (
          <span>{formatDate(props?.row?.original?.endDate)}</span>
        ),
      },
      {
        Header: "Employee Name",
        accessor: "userName",
        
      },
      {
        Header: "Reason For Leave",
        accessor: "leaveReason",
        disableSortBy: true,
        disableFilters:true
      },
      {
        Header: "Select",
        accessor:"select",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: false,
        disableSortBy: true,
        Filter:SelectColumnFilter
      },
    ],
    [SelectColumnFilter,tableData]
  );

  useEffect(() => {
    dispatch(setApprovedLeaves({
      selectedUser:selectedUser?.value
    }));

    return () => {
      dispatch(clearLeaves());
    };
  }, [dispatch, selectedUser]);
  
  useEffect(() => {
    dispatch( updateTrigger({index:location?.state?.index,data:leaveInfo?.data}))

  }, [leaveInfo?.data]);
  
  
  

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Approve Leave Request" />

        <section className="area-creation-content__info">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-md-4 col-lg-3 util-tp-filter">
              <div>
                <p className="util-select-user__label mb-2">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={userSelect}
                    value={selectedUser}
                    onChange={(selectedUser)=>setselectedUser(selectedUser)}
                    placeholder="Select User"
                  />
                </div>
              </div>
          </div>
        <div className="d-flex justify-content-end gap-5 pe-2">
          <button
            type="submit"
            className="button-submit-green"
            onClick={(e) => {
              e.preventDefault();
              setShowEdit(true);
            }}
          >
            <span className="button-submit-green__icon-container">
              <BsCheck2Circle className="button-submit-green__icon" />
            </span>
            <span className="button-submit-green__text">Actions</span>
          </button>
        </div>
        </div>
        {tableData.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2 mt-5">
              Showing ({tableData.length}) Entries
            </h2>
          )}
          <div className="filter-table">
          
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup/> : null}
        </section>
      </div>
    </div>
  );
};

export default ApprovalLR;
