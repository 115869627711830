export const title = "Approvals";

export const links = [
  {
    name: "Call Report",
    route: "approve-call-report",
    accessor: "callReports",
  },
  {
    name: "Tour Program",
    route: "approve-tp",
    accessor: "tps",
  },
  {
    name: "Call Planning",
    route: "approve-cp",
    accessor: " callPlanning",
  },
  {
    name: "Expense",
    route: "approve-expense",
    accessor: "expenses",
  },
  {
    name: "Leave Request",
    route: "approve-leave-request",
    accessor: "leaveRequest",
  },
  {
    name: "Doctors",
    route: "approve-doctors",
    accessor: "doctors",
  },
  {
    name: "Chemists",
    route: "approve-chemists",
    accessor: "chemists",
  },
  {
    name: "Stockists",
    route: "approve-stockists",
    accessor: "stockists",
  },
  {
    name: "City",
    route: "approve-city",
    accessor: "cities",
  },
  {
    name: "Route",
    route: "approve-route",
    accessor: "routes",
  },
  {
    name: "Samples",
    route: "samples-approve",
    accessor: "samplemanagements",
  },
  {
    name: "Gifts",
    route: "gifts-approve",
    accessor: "giftmanagements",
  },
  {
    name: "Deletion Request",
    route: "deletion-request",
    accessor: "preferences"
  }
];

