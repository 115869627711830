import React, { useState, useEffect, useMemo, Suspense, lazy } from "react";
import { MdOutlineEvent, MdOutlineGroup } from "react-icons/md";
import OverviewGraph from "./OverviewGraph";
import ProductivityGraph from "./ProductivityGraph";
import "./index.css";
import {
  getAttendanceOverviewChart,
  getMonthWiseAttendance,
  editAttendance
} from "../../../services/attendance";
import { monthOptions, yearOptions } from "../../../utils/helper";
import {
  createSetForAttendance,
  generateSelectUser,
} from "../../../utils/serialiseResponse";
import Select from "react-select";
import {
  dcrStyles,
  selectUserStyles,
} from "../../public/stylesheets/selectStyles";
import { useDispatch, useSelector } from "react-redux";
import { viewUsersForUtilities } from "../../../services/utilities";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import { BsCheck2Circle, BsXCircle } from "react-icons/bs";
import customToast from "../../../components/CustomToast";
import { clearUsers, setUsersForUtilities } from "../../../reducers/users/employeeReducer";
const DatePickers = lazy(() => import("../../../components/DatePicker"))

const Attendance = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const employees = useSelector(({ employee }) => employee);

  const [present, setPresent] = useState();
  const [sundays, setSundays] = useState();
  const [leave, setLeave] = useState();
  const [edit, setEdit] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  // const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState({label:loggedIn.user?.firstName + " " + loggedIn.user?.lastName, value:loggedIn.user?._id, des:loggedIn.user?.des});
  const [attendanceOverview, setAttendanceOverview] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [data, setData] = useState();
  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );
 

  useEffect(() => {
    const data = {
      selectedUser: selectedUser?.value,
      month: month,
      year: Number(year),
    };
    
    getMonthWiseAttendance(data).then(({data}) => {
      setPresent(data?.present);
      setSundays(data?.holidays);// these are holidays
      setLeave(data?.absent);// these are not leaverequests
      setAttendanceData(data?.data);
      setLeaveData(data?.leaves);
      setData(data);
    });
  }, [month, year, selectedUser]);


  useEffect(() => {
    getAttendanceOverviewChart({
      selectedUser: selectedUser?.value,
    })
      .then((res) => {
        
        setAttendanceOverview(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedUser]);

  const dataSet = useMemo(
    () => createSetForAttendance(attendanceData),
    [attendanceData]
  );
  
  // useEffect(() => {
  //   try {
  //     viewUsersForUtilities().then((res) => {
  //       setEmployees([...res.data]);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);
  useEffect(() => {
        dispatch(setUsersForUtilities({ includeMe: true }))
        return () => {
          dispatch(clearUsers());
        };
      }, [dispatch]);

  const handleChanges = (date, typ)=>{
    let dateString = moment
    .tz(new Date(date), "Asia/Kolkata")
    .format()
    .toString()
    .substring(0, 10);
    let foundDate = attendanceData.findIndex((e)=>e.dateString === dateString);
    if(foundDate !== -1){
      const obj = attendanceData[foundDate] ;
      if(typ === 'present'){
        obj.isPresent = true ;
        obj.isLeave = false ;
        obj.isHoliday = false ;
      }else if(typ === 'leave'){
        obj.isPresent =  false;
        obj.isLeave =  true;
        obj.isHoliday = false ;
      }
      setAttendanceData([...attendanceData]);
    }
    
  }
  const getObj = (date)=>{
    let dateString = moment
    .tz(new Date(date), "Asia/Kolkata")
    .format()
    .toString()
    .substring(0, 10);
    let foundDate = attendanceData.findIndex((e)=>e.dateString === dateString);
    if(foundDate !== -1){
      const obj = attendanceData[foundDate] ;
      return obj ;
    }
    return null ;
  }
  const handleSubmit = (e)=>{
    e.preventDefault();
    editAttendance("attendance/attendance", data._id,{data: attendanceData}).then(({data})=>{
      customToast.success("Successfully updated attendance")
      setEdit(false);
      setPresent(data?.present);
      setSundays(data?.holidays);// these are holidays
      setLeave(data?.absent);// these are not leaverequests
      setAttendanceData(data?.data);
      setLeaveData(data?.leaves);
      setData(data);
    }).catch(err=>{
      // console.log(err);
      customToast.error("Sorry Something Went Wrong!");
    })
  }
  return (
    <div className="main-content">
      <div className="attendance__content">
        <div className="row">
          <h2 className="web-app__heading mb-4">Attendance</h2>
          <div className="attendance__content-filter">
            <div className="attendance__content-filter-right-div">
              <div className="util-tp-filter">
                <p className="mb-3">
                  Select Month <span style={{ color: "#e74c3caa" }}>*</span>
                </p>
                <Suspense>
                  <DatePickers
                    placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                    setMonth={setMonth}
                    setYear={setYear}
                  />
                </Suspense>
              </div>

              
            </div>
            {loggedIn?.user?.des < 121 ||
            loggedIn?.user?.designation?.priority > 1 ||
            loggedIn?.user?.isAdmin ? (
              <div className="util-tp-filter">
                <p className="util-select-user__label">Select User</p>
                <div className="util-select-user">
                  <MdOutlineGroup className="util-select-user__icon" />
                  <Select
                    styles={selectUserStyles}
                    options={employeeSelect}
                    placeholder="Select User"
                    className="mt-3"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser({ ...e })}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3">
            <div className="attendance__highlights">
              <div className="attendance__highlights-icon">
                <MdOutlineEvent className="best-month tp__activity-types-icon-4" />
              </div>
              <div className="attendance__highlights-info">
                <h4>Leaves</h4>
                <p>{leaveData}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3">
            <div className="attendance__highlights">
              <div className="attendance__highlights-icon">
                <MdOutlineEvent className="max-target" />
              </div>
              <div className="attendance__highlights-info">
                <h4>Present</h4>
                <p>{present}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3">
            <div className="attendance__highlights">
              <div className="attendance__highlights-icon">
                <MdOutlineEvent className="halfDay" />
              </div>
              <div className="attendance__highlights-info">
                <h4>Holidays</h4>
                <p>{sundays}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3">
            <div className="attendance__highlights">
              <div className="attendance__highlights-icon">
                <MdOutlineEvent className="leave" />
              </div>
              <div className="attendance__highlights-info">
                <h4>Absent</h4>
                <p>{leave}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-7">
            <div className="attendance__overview h-100">
              <div className="attendance__overview-heading">
                <h2 className="web-app__heading-blue">Attendance Overview</h2>
              </div>
              <div className="attendance__overview--graph">
                {attendanceOverview.length > 0 ? (
                  <OverviewGraph data={attendanceOverview} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5">
            <div className="attendance__overview">
              <h2 className="web-app__heading-blue mt-3">Reports Submitted</h2>
              <div className="attendance__overview--graph">
                <ProductivityGraph
                  month={month}
                  year={Number(year)}
                  selectedUser={selectedUser?.value}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="filter-table m-0">
            <div className="attendance-calendar-tags d-flex mb-4 gap-md-3 gap-lg-5 mt-5">
              <h4>
                <span className="tag-indicator-p me-2"></span>Present
              </h4>
              <h4>
                <span className="tag-indicator-a me-2"></span>Absent
              </h4>
              <h4>
                <span className="tag-indicator-l me-2"></span>Leave
              </h4>
              <h4>
                <span className="tp-indicator-5 me-2"></span>Holiday
              </h4>
              {!edit ? (new Date().getMonth() > month) && <button type="button" onClick={(e)=> setEdit(true)} className="button-submit-green me-5 ms-auto">
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Edit</span>
              </button>
              :<div className="d-flex gap-4 ms-auto align-items-center justify-content-center">
                <button
                  type="button"
                  onClick={(e)=>{
                    setEdit(false);
                    setMonth({...month});
                  }}
                  className="button-blue-gradient "
                >
                  Cancel
                </button>
                <button type="button" onClick={handleSubmit} className="button-submit mt-0">
                  Save
                </button>
              </div>
            }
            </div>
            <Calendar
              className="attendance"
              value={new Date(Number(year), Number(month), 1)}
              onActiveStartDateChange={(e) => {
                setMonth(monthOptions[e?.activeStartDate.getMonth()]);
                setYear({ label: e?.activeStartDate.getFullYear() });
              }}
              tileClassName={({ date, view }) => {
                if (view === "month") {
                  if (dataSet.has(`present:${date.getDate()}`) && date < new Date()) {
                    return "present";
                  } 
                  else if(dataSet.has(`leave:${date.getDate()}`)){
                    return "leave";
                  }
                  else if(dataSet.has(`holiday:${date.getDate()}`)){
                    return 'holiday';
                  }
                  else if(date < new Date()) {
                    return "absent";
                  }
                }
              }}
              tileContent={({date, view}) =>{
                const target = getObj(date);
                return view === 'month' && target && edit &&
                <div className="d-flex gap-3 justify-content-center p-2 attender">
                   <label>
                    <input type="checkbox" checked={target.isPresent} className={target.isPresent && 'pcheckbox'} onChange={() => {handleChanges(date, 'present')}} />
                    <span></span>
                  </label>
                  <label>
                    <input type="checkbox" checked={target.isLeave} className={target.isLeave && 'lcheckbox'} onChange={() => {handleChanges(date, 'leave')}} />
                    <span></span>
                  </label>
                </div> ;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
