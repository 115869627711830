import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import PageTitle from "../../../../../components/PageTitle";
import customToast from "../../../../../components/CustomToast";
import QuantitySelector from "../../../../../components/QuantitySelector";
import {
  clearProductRanges,
  setProductRanges,
} from "../../../../../reducers/products/rangeReducer";
import {
  MdCheckCircleOutline,
  MdDelete,
  MdDeleteOutline,
  MdOutlineEdit,
} from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

import { adminStyles, dcrStyles } from "../../../../public/stylesheets/selectStyles";
import { allotUserSamples, deleteUserSamples } from "../../../../../reducers/reports/sampleAllotmentReducer.js";
import {
  monthOptions,
  returnMonthFromIndex,
  yearOptions,
} from "../../../../../utils/helper";
import { viewProductV1 } from "../../../../../services/products";
import UTPreview from "../../../../../components/UserTarget/UTPreview";
import { useMemo } from "react";
import Table from "../../../../../components/Table";
import { viewSampleData, viewSampleGiftData } from "../../../../../services/utilities";
import { serialiseSSG } from "../../../../../utils/serialiseResponse";
import UTEditableRow from "../../../../../components/UserTarget/UTEditable/index.jsx";
import UTReadOnly from "../../../../../components/UserTarget/UTReadable/index.jsx";
import moment from "moment-timezone";
import { IoMdRemoveCircle } from "react-icons/io";
const DatePickers = lazy(() => import("../../../../../components/DatePicker"))

const headerSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "none",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",
    width: "100%",
    cursor: "pointer",
    outline: "none",
    border: "none"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",

  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",

  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    outline: "none",
    border: "none",

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    "&:hover": {
      color: "#9E9AD1",
    },
    backgroundColor: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "5px",
    height: "5px",
    padding: "0",
    "&:first-child": {
      width: "100% !important",
      height: "100% !important"
    }
  }),
};

const dropdownOptions =
  [{ label: "MRP", value: "mrp" }, { label: "PTS", value: "pts" }, { label: "PTR", value: "ptr" }, { label: "CUSTOM", value: "custom" }]

const AdminAllotSample = () => {
  
  const nameRef = useRef("");
  const customValue = useRef(0);
  const totalQt = useRef(0);
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector(({ user }) => user);

  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [prodArray, setProdArray] = useState([]);
  const [edit, setEdit] = useState(false); 
  const [editing, setEditing] = useState(false);
  const [serial, setSerial] = useState(1);
  const [prod, setProd] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [prodId, setProdId] = useState("");
  const [products, setProducts] = useState([]);
  const [alreadyAlloted, setAlreadyAlloted] = useState([]);
  const [type, setType] = useState({ value: 1, label: "Already Allotted" });
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isDeleted,setIsDeleted] = useState(false);
  const [priceHeader, setPriceHeader] = useState({ label: "PTR", value: "ptr" });
  const [isAlloted, setIsAlloted] = useState(false);
  const [customPrice, setCustomPrice] = useState(0)
  const typeOptions = [
    { value: 1, label: "Already Allotted" },
    { value: 2, label: "Allot More" },
  ];

  // useEffect(() => {
  //   dispatch(setProductRanges(""));

  //   return () => {
  //     dispatch(clearProductRanges());
  //   };
  // }, [dispatch]);

  // const handleAdd = () => {
  //   if (!month) return customToast.error("Please select the month");
  //   if (!year) return customToast.error("Please select the year");

  //   let oldProducts = products;
  //   const newProduct = {
  //     submitter: loggedIn?.jwt?.id,
  //     user: params?.userID ? params?.userID : loggedIn?.user?._id,
  //     month: month?.value,
  //     year: Number(year?.label),
  //     serial,
  //     productName: prod?.name,
  //     range: prod?._id,
  //     issued: quantity,
  //     balance: quantity,
  //     // amount: quantity * prod?.ptr,
  //     amount:
  //       priceHeader?.label === "PTS"
  //         ? prod?.pts * quantity
  //         : priceHeader?.label === "MRP"
  //           ? prod?.mrp * quantity
  //           : priceHeader?.label === "PTR"
  //             ? prod?.ptr * quantity
  //           : priceHeader?.label === "CUSTOM"
  //             ? customPrice * quantity
  //             : 0,
  //   };

  //   setSerial((old) => old + 1);
  //   setProdId("");
  //   setProducts([...oldProducts, newProduct]);
  //   setProdArray(prodArray.filter((el) => el._id !== prod._id));
  //   setQuantity(0);
  //   setCustomPrice(0);
  //   setShowEdit(false);
  // };

  const handleAddProduct = async(productInfo) =>{

    let oldProducts = products;
    const newProduct = {
      submitter: loggedIn?.jwt?.id,
      user : params?.userID ? params?.userID : loggedIn?.user?._id,
      month : Number(month),
      year: Number(year),
      serial,
      productName : productInfo?.name,
      range : productInfo?._id,
      issued: totalQt.current,
      balance: totalQt.current,
      amount : 
      priceHeader?.label === "PTS"
      ? productInfo?.pts * Number(totalQt.current)
      : priceHeader?.label === "MRP"
      ? productInfo?.mrp * Number(totalQt.current)
      : priceHeader?.label === "PTR"
      ? productInfo?.ptr * Number(totalQt.current)
      : priceHeader?.label === "CUSTOM"
      ? Number(customValue.current) * Number(totalQt.current)
      : 0,
    };

    setSerial((old) => old + 1);
    setProdId("");
    setProducts([...oldProducts, newProduct]);
    setProdArray(prodArray.filter((el) => el._id !== productInfo._id));
    setQuantity(0);
    setCustomPrice(0);
    setShowEdit(false);
  };

  useEffect(() => {
    viewProductV1({sample:true,  userId: params.userID}).then((response) => {
      setProdArray(response?.data);
    });
  }, [params]);

  useEffect(() => {
  if(type?.value === 1){
    const alreadyAllotedSample = async () => {
      let data = {
        month: month,
        year: Number(year),
        selectedUser: location?.state?._id,
      };
      viewSampleData(data).then((res) => {
        setAlreadyAlloted(res.data)
       }
      );
    };
    alreadyAllotedSample();
  }
  }, [month,isDeleted, type,year, isAlloted]);



  const previewColumn = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor : "productName",
        showTotal : true,
        placeholderTotal : "Total"
      },
      {
        Header: "Quantity",
        accessor : "issued",
        showTotal: true,
        totalAccessor : "issued"
      },
      {
        Header : "Amount",
        accessor: "amount",
        showTotal: true,
        totalAccessor : "amount"
      }
    ],[products]
  );


  const EditPopup = ({ data }) => {
    const handleSubmit = (e) => {
      e.preventDefault();
      try {
        dispatch(allotUserSamples({ allotData: data })).then(() => {
          setProducts([]);
          setIsAlloted(!isAlloted);
          if(loggedIn?.user?.des === 101){
            customToast.success("Samples approved successfully");
          } else {
            customToast.success("Samples sent for Approval");
          }
        });
      } catch (error) {
        customToast.error("Unable to allot samples");
      }
      setShowEdit(false);
    };

    return (
      <section className="edit-popup edit-popup__sales">
        <div className="edit-content__sales">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Sample Allotment</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleSubmit}>
          <div className="filter-table" style={{minHeight:"150px", maxHeight:"350px" , overflow:"auto"}}>
            <Table data={products} columns={previewColumn}/>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-submit-green mt-4">
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Allot Sample</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };
  const DeletePopup = ({ data }) => {
    const dispatch = useDispatch();
    const [newQty, setnewQty] = useState(null)

    const handleDelete = async (e) => {
      e.preventDefault();
      try {
        dispatch(deleteUserSamples({ 
          itemId: data?.itemId, 
          selectedUser: location.state?._id,
          newQty: newQty
        })).then(() => {
          setIsDeleted(!isDeleted);
          setDeletePopup(false);
          customToast.success("Allotment deleted successfully");
        });
      }
      catch(error) {
        customToast.error("We encountered an error!");
        console.error(error);
      }
    };

    return (
      <section className="edit-popup" style={{ position: "absolute", top: "50%" }}>
        <div className="edit-content" style={{ width: "40%" }}>
          <div className="edit-popup__heading" style={{ display: "flex", justifyContent: "flex-end" }}>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => {
                setDeletePopup(false)
                setDeleteData(null)
              }}
            />
          </div>
          <div>Deallot the quantity </div>

          <QuantitySelector
            initialValue={data?.issued}
            minValue={1}
            maxValue={data?.issued}
            onQuantityChange={(value) => setnewQty(value)}
          />

          <div className="edit-form-button-container">
            <button
              type="submit"
              className="button-delete mt-4"
              onClick={handleDelete}
            >
              <span className="button-delete__icon-container">
                <IoMdRemoveCircle className="button-delete__icon" />
              </span>
              <span className="button-delete__text">Confirm</span>
            </button>
            <button
              className="button-submit-green mt-4"
              onClick={() => {
                setDeletePopup(false)
                setDeleteData(null)
              }}
            >
              <span className="button-submit-green__icon-container">
                <RiCloseCircleLine className="button-submit-green__icon" style={{ fontSize: "1.5em" }} />
              </span>
              <span className="button-submit-green__text">Cancel</span>
            </button>
          </div>
        </div>
      </section>
    );
  };

  const columns = useMemo(() => {
  
    if (loggedIn?.user?.des === 101) {
      return [
        // {
        //   Header: "Sr. No",
        //   accessor: "sr",
        //   disableFilters: true,
        //   minWidth: 40,
        //   maxWidth: 40,
        // },
        {
          Header: "Sample",
          accessor: "itemName",
          minWidth: 80,
          maxWidth: 80,
        },
        {
          Header: "Allotted Quantity",
          accessor: "issued",
          disableSortBy: true,
          minWidth: 80,
          maxWidth: 80,
        },
        {
          Header: "DeAllot",
          accessor: "4",
          disableFilters: true,
          disableSortBy: true,
          minWidth: 50,
          maxWidth: 50,
          Cell: (props) => {
            return (
              <span
                className="react-table-view-link"
                onClick={() => {
                  setDeleteData(props?.row?.original)
                  setDeletePopup(true);
                }}
              >
                <IoMdRemoveCircle className="icon-color-error" />
              </span>
            );
          },
        },
      ];
    } else {
      return [
        // {
        //   Header: "Sr. No",
        //   accessor: "sr",
        //   disableFilters: true,
        //   minWidth: 40,
        //   maxWidth: 40,
        // },
        {
          Header: "Sample",
          accessor: "itemName",
          minWidth: 80,
          maxWidth: 80,
        },
        {
          Header: "Allotted Quantity",
          accessor: "issued",
          disableSortBy: true,
          minWidth: 80,
          maxWidth: 80,
        },
      ];
    }
  }, []);
  

  const tableData = useMemo(
    () => serialiseSSG(alreadyAlloted),
    [alreadyAlloted]
  );

  const handleEditRow = (value) => {
    nameRef.current = value;
    setEdit(true);
    setEditing(!editing);
  };

  const handlePriceHeaderChange= async(e) =>{
    setPriceHeader(e);
  }

  const allotColumns = useMemo(
    () => [
      {
        Header: "Product name",
        accessor: "name",
      },
      {
        Header:(
          <Select
          styles={headerSelectStyles}
          options={dropdownOptions}
          placeholder={priceHeader?.label}
          onChange={handlePriceHeaderChange}
          components={{IndicatorSeparator: () => null}}/>
        ),
        accessor:"ptr",
        disableFilters:true,
        disableSortBy: true,
        Cell: (props) =>{
          if(priceHeader?.label === "PTR")
         {
          return <div>
            {props?.row?.original?.ptr}
          </div>
         }
         else if(priceHeader?.label === "PTS")
         {
          return <div>
            {props?.row?.original?.pts}
          </div>
         }
         else if(priceHeader?.label === "MRP")
         {
          return <div>
            {props?.row?.original?.mrp}
          </div>
         }
         else{
          return <input
          type="number"
          className="sales-table__input"
          onChange={(e) => {customValue.current = e.target.value}}/>
         }
        }
      },
      {
        Header:"Quantity",
        accessor:"",
        Cell:(props) =>{
          const editable = String(nameRef.current) === String(props?.row?.original?.name);
          return (editable) ?
          <input
          type="number"
          defaultValue={0}
          className="sales-table__input"
          onChange={(e) =>{
            totalQt.current = e.target.value;
          }}/>
          : 0
        }
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) =>{
          const editable = String(nameRef.current) === String(props?.row?.original?.name);
          return (edit && editable) ?(
            <button
            onClick={() => {
              handleAddProduct(props?.row?.original);
              setEdit(false);
              setEditing(!editing);
            }}>
              Save
            </button>
          )
          :
          (
            <div className="d-flex gap-1 justify-content-center">
              <MdOutlineEdit type="button"
              onClick={() => {
                handleEditRow(props?.row?.original?.name);
              }}/>
            </div>
          )
        }
      }
    ],[prodArray,priceHeader,editing]
  );

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        {loggedIn?.user?.des === 121 ?(
          <PageTitle
            title={`Allot Sample to ${loggedIn?.user?.firstName} ${loggedIn?.user?.lastName}`}
            hierarchy={true}
          />
        ) :
          <PageTitle
            title={`Allot Sample to ${location.state?.firstName} ${location.state?.lastName}`}
            hierarchy={true}
          />}
        <section className="area-creation-content__form">
          <form>
            <div className="d-flex align-items-center">
              <div className="util-tp-filter me-5">
                <p className="">
                  Select Type <span className="asterisk-imp">*</span>
                </p>
                <br />
                <Select
                  styles={dcrStyles}
                  placeholder="Select Type"
                  value={type}
                  options={typeOptions}
                  onChange={(e) => {
                    setType({ ...e });
                  }}
                />
              </div>
            </div>
          </form>
        </section>

        
        <section className="area-creation-content">
          <div className="filter-table filter-table__sg">
            {type?.value === 1 ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <Table columns={allotColumns} data={prodArray}/> 

            )}
          </div>
        </section>

        <section
          className="area-creation-content__info"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {type?.value !== 1 ? (
            <button
              type="submit"
              className="button-blue-gradient"
              onClick={() => setShowEdit(true)}
            >
              Preview
            </button>
          ) : null}
          {showEdit ? <EditPopup data={products} /> : null}
          {deletePopup ? <DeletePopup data={deleteData} /> : null}
        </section>
      </div>
    </div>
  );
};

export default AdminAllotSample;
