import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import {
  addHoliday,
  clearHolidays,
  deleteHoliday,
  setHoliday,
} from "../../../../reducers/holidays";
import { formatDate } from "../../../../utils/helper";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import {
  MdDeleteOutline,
  MdCheckCircleOutline,
  MdDelete,
} from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import {
  clearStates,
  setStates,
} from "../../../../reducers/locations/stateReducer";

const holidayType = [
  { value: 0, label: "National" },
  { value: 1, label: "State" },
];

const AdminHolidays = () => {
  const dispatch = useDispatch();
  const states = useSelector(({ state }) => state);
  const holidayData = useSelector(({ holiday }) => holiday);

  const [type, setType] = useState();
  const [date, setDate] = useState("");
  const [state, setState] = useState([]);
  const [title, setTitle] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [holidays, setHolidays] = useState();
  const [currentDate, setcurrentDate] = useState(new Date().toISOString().split('T')[0])

  

  const selectData = useMemo(
    () => generateSelectData(states.data, "name"),
    [states]
  );

  const EditPopup = ({ data }) => {
    const dispatch = useDispatch();

    const handleDelete = (e) => {
      e.preventDefault();
      dispatch(deleteHoliday(data?._id));
      setShowEdit(false);
    };

    return (
      <section className="edit-popup">
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Delete Holiday</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form onSubmit={handleDelete}>
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you really want to delete this holiday?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button type="submit" className="button-delete mt-4">
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Yes</span>
              </button>

              <button
                type="button"
                className="button-submit-green mt-4"
                onClick={() => setShowEdit(false)}
              >
                <span className="button-submit-green__icon-container">
                  <MdCheckCircleOutline className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">No</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const setEditForm = (data) => {
    setShowEdit(false);
    setTitle(data?.name);
    setHolidays(data);
  }; 



  const columns = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        Cell: (props) => <span>{formatDate(props?.row?.original?.date)}</span>,
      },
      {
        Header: "Type",
        accessor: "type.label",
        disableSortBy: true,
      },
      {
        Header: "State",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const capitalize = (text) =>
          text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : text;

          const state = props?.row?.original?.type?.label === "State" ? props?.row?.original?.state : 'N/A';
          return (
           <span>{state === 'N/A' ? state : capitalize(state)}</span>
          );
        }

      },
      {
        Header: "Holiday",
        accessor: "holidayTitle",
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <MdDelete className="icon-color-error" />
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(!type || !title || !date) return customToast.error("Please fill all the fields")
    const data = {
      date: date,
      type,
      state: state,
      month: new Date(date).getMonth(),
      year: new Date(date).getFullYear(),
      holidayTitle: title,
    };
    
    try {
      await dispatch(addHoliday(data));
      customToast.success("Holiday added successfully");
      setDate("");
      setTitle("");
      setType("");
      setState([]);
    } catch (error) {
      customToast.error("We encountered some error");
    }
  };

  useEffect(() => {
    dispatch(setHoliday());
    dispatch(setStates());

    return () => {
      dispatch(clearStates());
      dispatch(clearHolidays());
    };
  }, [dispatch]);
  
  const tableData = useMemo(() => serialise(holidayData.data), [holidayData]);
  
  return (
    <main className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Create Holidays" />

        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="type">
                  Holiday Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={holidayType}
                  onChange={({ value, label }) => setType({ value, label })}
                  styles={adminStyles}
                  placeholder="Select Holiday Type"
                />
              </div>

              {type?.value === 1 ? (
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="state">
                    Select State <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    name="state"
                    id="state"
                    isMulti={true}
                    value={state}
                    options={selectData}
                    onChange={(e) => setState([...e])}
                    styles={adminStyles}
                    placeholder="Select State"
                    className="multi-select-dropdown"
                  />
                </div>
              ) : null}

              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="holiday">
                  Select Date <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="date"
                  className="me-5"
                  id="holiday"
                  placeholder="Select Holiday"
                  value={date}
                  min={currentDate}
                  onChange={({ target }) => setDate(target.value)}
                />
              </div>

              <div
                className={`col-sm-12 col-md-4 col-lg-4 ${
                  type?.value === 1 ? "mt-5" : ""
                }`}
              >
                <label htmlFor="title">
                  Holiday Title <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="me-5"
                  id="title"
                  placeholder="Enter title"
                  value={title}
                  onChange={({ target }) => setTitle(target.value)}
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 mt-5">
                <button
                  type="submit"
                  className={`button-blue-gradient ${
                    type?.value === 1 ? "mt-5" : ""
                  }`}
                >
                  Add Holiday
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className="area-creation-content__info">
          <div className="filter-table">
          <Table columns={columns} data={tableData&&tableData.length>0?tableData:[]} />
          </div>
          {showEdit ? <EditPopup data={holidays} /> : null}
        </section>
      </div>
    </main>
  );
};

export default AdminHolidays;
