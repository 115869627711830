import {
  approveLR,
  rejectLR,
  viewLeaves,
  viewLeavesForApproval,
  addLeaveRequest,
} from "../services/leaveRequest.js";
import { approveCount } from "./approveCountReducer.js";

const initialState = {
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LEAVES":
      return action.payload;

    case "SET_APPROVED_LEAVES":
      return action.payload;

    case "CREATE_LEAVES":
      return [action.payload];

    case "CLEAR_LEAVES":
      return initialState;

    case "APPROVE_LEAVES":
      return {data:state.data};

    case "REJECT_LEAVES":
      return {data:state.data};

    default:
      return state;
  }
};

export const addLeaves = (data) => {
  return async (dispatch) => {
    try {
      data = await addLeaveRequest(data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "CREATE_LEAVES",
      payload: data,
    });
    dispatch(setLeaves());
  };
};

export const setLeaves = () => {
  return async (dispatch) => {
    const { data } = await viewLeaves();

    dispatch({
      type: "SET_LEAVES",
      payload: { data: data },
    });
  };
};

export const setApprovedLeaves = (filters) => {
  return async (dispatch) => {
    const { data } = await viewLeavesForApproval( filters, { viewForApproval: true });

    dispatch({
      type: "SET_APPROVED_LEAVES",
      payload: { data: data },
    });
    dispatch(approveCount())
  };
};

export const approveLeave = (id, data) => {
  return async (dispatch) => {
    try {
      data = await approveLR(id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "APPROVE_LEAVES",
      payload: { data: data },
    });
    dispatch(setApprovedLeaves());
  };
};

export const rejectLeave = (id, data) => {
  return async (dispatch) => {
    try {
      data = await rejectLR(id, data);
    } catch (e) {
      if (data.error) throw data.error;
    }

    dispatch({
      type: "REJECT_LEAVES",
      payload: { data: data },
    });
    dispatch(setApprovedLeaves());
  };
};

export const clearLeaves = () => {
  return {
    type: "CLEAR_LEAVES",
  };
};

export default reducer;
