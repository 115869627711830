import { useMemo, useRef, useState, useEffect, Suspense, lazy } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import serialise from "../../../../utils/serialiseResponse";
import { formatDate } from "../../../../utils/helper";
import Table from "../../../../components/Table";
import { FaEye } from "react-icons/fa";
import { AiFillFolder } from "react-icons/ai";
import { MdArrowBackIosNew } from "react-icons/md";
import { viewSecondarySales } from "../../../../services/sales";
import customToast from "../../../../components/CustomToast";
import moment from "moment-timezone";
import { setInputValue } from "../../../../reducers/inputStates/inputValueReducer";
const DatePickers = lazy(() => import("../../../../components/DatePicker"));

const AllSecondarySales = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { month, year } = useSelector(({ inputValue }) => inputValue);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  console.log(useSelector(({ inputValue }) => inputValue));
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        showTotal: true,
        placeholderTotal: "Total",
        Cell: (props) => (
          <span>
            {formatDate(
              props?.row?.original?.date
                ? props?.row?.original?.date
                : props?.row?.original?.createdAt
            )}
          </span>
        ),
      },
      {
        Header: "Stockist",
        accessor: "stockist",
        disableSortBy: true,
      },
      {
        Header: "Headquarter",
        accessor: "headquarter",
        disableSortBy: true,
      },
      {
        Header: "Total Quantity",
        accessor: "totalQuantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "totalQuantity",
      },
      {
        Header: "Sales Quantity",
        accessor: "salesQuantity",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "salesQuantity",
      },
      {
        Header: "Sales Value",
        accessor: "totalSales",
        disableFilters: true,
        disableSortBy: true,
        showTotal: true,
        totalAccessor: "totalSales",
      },
      {
        Header: "View",
        accessor: "vi",
        minWidth: 70,
        maxWidth: 70,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    [tableData]
  );
  const handleSubmit = async () => {
    if (loading) return;
    if (!year) {
      return customToast.error("Please select a month and year");
    }
    setLoading(true);
    try {
      const { data } = await viewSecondarySales({ month: month, year: year });
      customToast.success("Successfully Fetched Secondary Sales");
      setTableData(serialise(data.data));
    } catch (err) {
      const { data } = err.response;
      customToast.error(data.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleSubmit();
  }, [month, year]);

  return (
    <main className="main-content admin-content">
      <div className="expense">
        <h3
          className="web-app__heading"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              All Secondary Sales
            </span>
          </div>
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="primarysales-filter">
            <div className="util-tp-filter">
              <p className="mb-2">
                Select Month <span className="asterisk-imp">*</span>
              </p>
              <Suspense>
                <DatePickers
                  placeholder={
                    (month || month === 0) && year
                      ? moment({ year, month }).format("MMM, YYYY")
                      : "Select Month"
                  }
                  setMonth={(month) => dispatch(setInputValue("month", month))}
                  setYear={(year) => dispatch(setInputValue("year", year))}
                />
              </Suspense>
            </div>
            {/* <div className="util-tp-filter">
              <div className="mb-2">
                <button
                  className="button-blue-gradient mt-5"
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div> */}
            <div className="util-tp-filter">
              <div className="mb-2">
                <button
                  type="button"
                  className="button-submit-green mt-5 ms-auto"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <span className="button-submit-green__icon-container">
                    <AiFillFolder className="button-submit-green__icon" />
                  </span>
                  <span className="button-submit-green__text ">
                    Close Secondary Sales
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="filter-table"
            style={{ minHeight: "150px", overflow: "auto" }}
          >
            <Table columns={columns} data={tableData} />
          </div>
        </form>
      </div>
    </main>
  );
};

export default AllSecondarySales;
