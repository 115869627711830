import { MdArrowBackIosNew, MdOutlineRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { formatDate } from "../../../../utils/helper";
import {
  dcrStyles
} from "../../../public/stylesheets/selectStyles";
import { useState, useEffect, useMemo, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  viewMissedReports,
  viewUsersForUtilities,
  viewUserwiseMissedReports,
} from "../../../../services/utilities";
import Table from "../../../../components/Table";
import { generateSelectData, generateSelectUser } from "../../../../utils/serialiseResponse";
import { addDays } from "date-fns";
import MonthWiseReports from "./MonthWiseReports";
import moment from "moment-timezone";
import DatePickers from "../../../../components/DatePicker";
import WhiteCircles from "../../../public/img/white-circles.png";


const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const showArr = [
  { value: "metMissedReport", label: "Met/Missed Report" },
  { value: "monthlyReport", label: "Monthly Report" },
  { value: "userwiseReport", label: "Userwise Report" },
]

const MissedDocReports = () => {
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);

  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [month, setMonth] = useState(moment().month());
  const [year, setYear] = useState(moment().year());
  const [type, setType] = useState({ value: 1, label: "Doctor" });
  const [displayReports, setDisplayReport] = useState([])

  const [employeeDataForExcel, setEmployeeDataForExcel] = useState();
  const [reportType, setReportType] = useState({ value: "metMissedReport", label: "Met/Missed Report" })

  //DisplayReports are used to show the reports on calendar page without duplicates
  //Reports contain duplicates and are sent to view details page
  //FinalReport is used for showing missed doc / chem / stock on calendar page

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const employeeData = useMemo(
    () => generateSelectData(employees, "fullName"),
    [employees]
  );

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
  });

  const columns = useMemo(
    () => [
      {
        Header: "Met/Missed",
        accessor: "",
        minWidth: 80,
        maxWidth: 100,
        Cell: (props) => {
          const category = props?.row?.original?.target?.category;
          if (
            // props?.row?.original?.target?.category?.label &&
            // props?.row?.original?.target?.category?.label
            //   .toLowerCase()
            //   .substring(0, 1) === "u"
            category?.value === 4
          ) {
            if (props.row.original?.meetingDates?.length >= 4) {
              return <span className="approved-text">Met</span>;
            } else if (props?.row?.original?.meetingDates?.length === 0) {
              return <span className="rejected-text">Missed</span>;
            } else {
              return <span className="pending-text">Partially Missed</span>;
            }
          } else if (
            // props?.row?.original?.target?.category?.label &&
            // props?.row?.original?.target?.category?.label
            //   .toLowerCase()
            //   .substring(0, 1) === "s"
            category?.value === 3
          ) {
            if (props.row.original?.meetingDates?.length >= 3) {
              return <span className="approved-text">Met</span>;
            } else if (props?.row?.original?.meetingDates?.length === 0) {
              return <span className="rejected-text">Missed</span>;
            } else {
              return <span className="pending-text">Partially Missed</span>;
            }
          } else if (
            // props?.row?.original?.target?.category?.label &&
            // props?.row?.original?.target?.category?.label
            //   .toLowerCase()
            //   .substring(0, 1) === "c"
            category?.value === 2
          ) {
            if (props.row.original?.meetingDates?.length >= 2) {
              return <span className="approved-text">Met</span>;
            } else if (props?.row?.original?.meetingDates?.length === 0) {
              return <span className="rejected-text">Missed</span>;
            } else {
              return <span className="pending-text">Partially Missed</span>;
            }
          } else {
            if (props.row.original?.meetingDates?.length >= 1) {
              return <span className="approved-text">Met</span>;
            } else if (props?.row?.original?.meetingDates?.length === 0) {
              return <span className="rejected-text">Missed</span>;
            } else {
              return <span className="pending-text">Partially Missed</span>;
            }
          }
        },
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        Header: "Doctor Name",
        accessor: "target.name",
        minWidth: 80,
        maxWidth: 100,
      },
      {
        Header: "Degree",
        accessor: "target.degree",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Category",
        accessor: "target.category.label",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const category = props?.row?.original?.target?.category;
          return category?.label ? category.label : category?.name;
        }
      },
      {
        Header: "Expected Visit",
        accessor: "",
        Cell: (props) => {
          const category = props?.row?.original?.target?.category;
          if (
            // props?.row?.original?.target?.category?.label &&
            // props?.row?.original?.target?.category?.label
            //   .toLowerCase()
            //   .substring(0, 1) === "u"
            category?.value === 4
          ) {
            return <span>4</span>;
          } else if (
            // props?.row?.original?.target?.category?.label &&
            // props?.row?.original?.target?.category?.label
            //   .toLowerCase()
            //   .substring(0, 1) === "s"
            category?.value === 3
          ) {
            return <span>3</span>;
          } else if (
            // props?.row?.original?.target?.category?.label &&
            // props?.row?.original?.target?.category?.label
            //   .toLowerCase()
            //   .substring(0, 1) === "c"
            category?.value === 2
          ) {
            return <span>2</span>;
          } else {
            return <span>1</span>;
          }
        },
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 80,
      },
      {
        Header: "Actual Visits",
        accessor: "visitedCount",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 50,
        maxWidth: 80,
      },
      {
        Header: "Meeting Date",
        accessor: "",
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.meetingDates.length > 0 ?
                props?.row?.original?.meetingDates?.map((date) => {
                  return (
                    <span>
                      {formatDate(date)}
                      {" |\n "}
                    </span>
                  );
                }) : "N/A"
              }
            </span>
          );
        },
        minWidth: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedUser, loggedIn, displayReports]
  );
  const chemStkColData = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "businessName",
      },
      {
        Header: "Met / Missed",
        accessor: "status",
        Cell: (props) => {
          return props.row.original?.isMet === 1 ? (
            <span className="approved-text">Met</span>
          ) : (
            <span className="rejected-text">Missed</span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Date",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props.row.original?.cdtOfReport
                ? formatDate(props.row.original?.cdtOfReport)
                : "N/A"}
            </span>
          );
        },
      },
      {
        Header: "Time",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props.row.original?.cdtOfReport
                ? moment(props.row.original?.cdtOfReport)
                  .tz("Asia/Kolkata")
                  .format("hh:mm A")
                : "N/A"}
            </span>
          );
        },
      },
    ],
    [displayReports]
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "user.name",
      },
      {
        Header: "Total Doctor",
        accessor: "target",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Met",
        accessor: "metCount",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Partially Missed",
        accessor: "partiallyMissedCount",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Missed",
        accessor: "missedCount",
        disableFilters: true,
        disableSortBy: true
      },

    ],
    [selectedUser, loggedIn, displayReports]
  );

  const chemStkColData2 = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "user.name",
      },
      {
        Header: type.label === "Chemist" ? "Total Chemist" : "Total Stockist",
        accessor: "target",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Met",
        accessor: "metCount",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Missed",
        accessor: "missedCount",
        disableFilters: true,
        disableSortBy: true
      },

    ],
    [selectedUser, loggedIn, displayReports]
  );

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      let tempdata = employeeData.filter((empData) => {
        if (empData.label === selectedUser.label) return true;
      });
      setEmployeeDataForExcel(tempdata[0]);
    } catch (error) {
      console.log(error);
    }
  }, [selectedUser]);

  useEffect(() => {
    // setshowAllMissed(false);
    // let data = {
    //   selectedUser: selectedUser?.value,
    //   // startDate: new Date(dateRange?.startDate).getTime(),
    //   // endDate: new Date(dateRange?.endDate),
    //   // startDate: dateRange.startDate.getTime(),
    //   // endDate: dateRange.endDate,

    const aysncFetch = async () => {

      if (reportType?.value === "userwiseReport") {
        viewUserwiseMissedReports(type?.label?.toLowerCase(), {
          // selectedUser: selectedUser?.value,
          month: month,
          year: year,
        })
          .then((resp) => {
            setDisplayReport(resp.data);
          })
          .catch((err) => {
            setDisplayReport([]);
            console.log(err);
          });
      };

      if (reportType?.value === "metMissedReport" && selectedUser) {

        viewMissedReports(type?.label?.toLowerCase(), {
          selectedUser: selectedUser?.value,
          month: month,
          year: year,
        })
          .then((resp) => {
            setDisplayReport(resp?.data);
          })
          .catch((err) => {
            setDisplayReport([]);
            console.log(err);
          });
      };
    }
    aysncFetch();
  }, [reportType, month, year, type, selectedUser]);


  return (
    <div className="expense">
      <div className="row">
        <div className="d-flex justify-content-between">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Missed Reports
            </span>
          </h2>
        </div>
        {
          reportType.value === "monthlyReport" && <MonthWiseReports showArr={showArr} reportType={reportType} setReportType={setReportType} />
        }
        {(reportType.value === "metMissedReport") && (
          <>
            {" "}
            <form>
              <div className="util-tp-query d-flex flex-wrap gap-4">
                <div className="util-tp-filter">
                  <p className="mb-3">Select Report Type</p>
                  <Select
                    options={showArr}
                    // className="mt-3"
                    styles={dcrStyles}
                    value={reportType}
                    onChange={(e) => {
                      setReportType(e)
                      // setPunchingReports([])
                    }}
                  />
                </div>

                <div className="util-tp-filter">
                  <p>
                    Select Doc / Chem / Stk{" "}
                    {/* <span className="asterisk-imp">*</span> */}
                  </p>
                  <Select
                    styles={dcrStyles}
                    placeholder="Select Type"
                    className="mt-3"
                    options={typeOptions}
                    value={type}
                    onChange={(e) => setType({ ...e })}
                  />
                </div>

                <div className="util-tp-filter">
                  <p className="mb-3">Select Month</p>
                  <Suspense>
                    <DatePickers
                      placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                      setMonth={setMonth}
                      setYear={setYear}
                    />
                  </Suspense>
                </div>

                {loggedIn?.user?.des < 121 || loggedIn?.user?.isAdmin ? (
                  <div className="util-tp-filter">
                    <p className="">Select User{" "}
                    </p>
                    <div className="util-select-user">
                      <Select
                        styles={dcrStyles}
                        options={employeeSelect}
                        placeholder="Select User"
                        className="mt-3"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser({ ...e })}
                      />
                      <MdOutlineRefresh
                        size={25}
                        className="icon-color-tertiary mt-4 ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedUser(null)}
                      />
                    </div>
                  </div>
                ) : null}

                {displayReports?.targets?.length > 0 &&
                  (
                    <div className="avg_data_call_data mt-5">
                      <div className="avg_call_data dashboard-stats__metrics-1">
                        <img
                          src={WhiteCircles}
                          alt="..."
                          className="dashboard-stats__metrics-white-circle"
                        />
                        <div className="avg_data_label">Met</div>
                        <div className="avg_data_value">
                          {displayReports?.metCount}
                        </div>
                      </div>
                      {
                        type?.value === 1 &&
                        (<div className="avg_call_data dashboard-stats__metrics-2">
                          <img
                            src={WhiteCircles}
                            alt="..."
                            className="dashboard-stats__metrics-white-circle"
                          />
                          <div className="avg_data_label">Partially Missed</div>
                          <div className="avg_data_value">
                            {displayReports?.partiallyMissedCount}
                          </div>
                        </div>)
                      }
                      <div className="avg_call_data dashboard-stats__metrics-3">
                        <img
                          src={WhiteCircles}
                          alt="..."
                          className="dashboard-stats__metrics-white-circle"
                        />
                        <div className="avg_data_label">Missed</div>
                        <div className="avg_data_value">
                          {displayReports?.missedCount}
                        </div>
                      </div>
                    </div>
                  )
                }

              </div>

            </form>

            <div className="filter-table">

              {displayReports && displayReports?.targets?.length > 0 && (
                <h2 className="web-app__heading my-4">
                  Showing ({displayReports?.targets.length}) Entries
                </h2>
              )}
              {displayReports && displayReports?.targets?.length > 0 && (
                <Table
                  columns={
                    type?.label?.toLowerCase() === "doctor"
                      ? columns
                      : chemStkColData
                  }
                  data={displayReports?.targets}
                  defaultPageSize={50}
                  empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                  fileSrc="Missed Reports"
                // exportExcel={false}
                // callback={(data)=>console.log(displayReports)}
                />
              )}

            </div>{" "}
          </>
        )}
        {(reportType.value === "userwiseReport") && (
          <>
            {" "}
            <form>
              <div className="util-tp-query d-flex gap-4">
                <div className="util-tp-filter">
                  <p className="mb-3">Select Report Type</p>
                  <Select
                    options={showArr}
                    styles={dcrStyles}
                    value={reportType}
                    onChange={(e) => {
                      setReportType(e)
                    }}
                  />
                </div>

                <div className="util-tp-filter">
                  <p>
                    Select Doc / Chem / Stk{" "}
                    {/* <span className="asterisk-imp">*</span> */}
                  </p>
                  <Select
                    styles={dcrStyles}
                    placeholder="Select Type"
                    className="mt-3"
                    options={typeOptions}
                    value={type}
                    onChange={(e) => setType({ ...e })}
                  />
                </div>

                <div className="util-tp-filter">
                  <p className="mb-3">Select Month</p>
                  <Suspense>
                    <DatePickers
                      placeholder={(month && year ) ? moment({ year, month }).format('MMM, YYYY') : "Select Month"}
                      setMonth={setMonth}
                      setYear={setYear}
                    />
                  </Suspense>
                </div>
              </div>
            </form>
            <div className="filter-table">
              {displayReports?.length > 0 && (
                <h2 className="web-app__heading my-4">
                  Showing ({displayReports.length}) Entries
                </h2>
              )}
              {displayReports?.length > 0 && (
                <Table
                  columns={
                    type?.label?.toLowerCase() === "doctor"
                      ? columns2
                      : chemStkColData2
                  }
                  data={displayReports}
                  defaultPageSize={50}
                  empl={selectedUser ? employeeDataForExcel : loggedIn?.user}
                  fileSrc="Missed Reports"
                />
              )}
            </div>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default MissedDocReports;
