import { addAnnouncement, getAnnouncement } from "../services/settings";

const initialState = {
    announcementMessage: null,
    announcementColor: null,
    announcementType: null,
};

const announcementReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_ANNOUNCEMENT':
            return {
                announcementMessage: action.payload.announcementText,
                announcementColor: action.payload.announcementColor,
                announcementType: action.payload.announcementType,
            };
        case 'FETCH_ANNOUNCEMENTS':
            return {
                announcementMessage: action.payload.announcementText,
                announcementColor: action.payload.announcementColor,
                announcementType: action.payload.announcementType,
            };
        default:
            return state;
    }
};

export const addAnnouncements = (data) => {
    return async (dispatch) => {
        try {
            const response = await addAnnouncement(data);
            dispatch({
                type: 'ADD_ANNOUNCEMENT',
                payload: response.data,
            });
        } catch (error) {
            console.error('Failed to add announcement', error);
        }
    };
};

export const fetchAnnouncements = () => {
    return async (dispatch) => {
        try {
            const response = await getAnnouncement();
            // const response = await getAnnouncementV1();
            dispatch({
                type: 'FETCH_ANNOUNCEMENTS',
                payload: response?.data?.res,
            });
        } catch (error) {
            console.error('Failed to fetch announcements', error);
        }
    };
};

export default announcementReducer;
