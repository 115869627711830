import { Outlet } from "react-router-dom";
import AdminNav from "../../../Admin/AdminSidenav";
import { title, links } from "./navData.js";

import "../../../public/stylesheets/admin-locations.css";
import { viewCallReport, viewCallReportForApproval } from "../../../../services/dcr";
import { viewTargetsForApproval } from "../../../../services/targets";
import { viewLocationsForApproval } from "../../../../services/locations";
import {getExpenseForApproval} from "../../../../services/expenses";
import {viewLeavesForApproval} from "../../../../services/leaveRequest";
import {viewTPForApproval, viewTPWithStatus} from "../../../../services/tourProgram";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { viewGiftsForapproval, viewSampleForApproval } from "../../../../services/reports.js";
import { viewCallPlanningForApproval } from "../../../../services/callPlanning.js";
import { getAllDeletionRequest } from "../../../../services/deletionRequest.js";
import { countApproval } from "../../../../services/notifications";

const Approvals = () => {

  const [data, setData] = useState([]);
  const [trigger, setTrigger] = useState({
    index:null,
    isChanged:true
  });
  let approvalLength = useSelector(state=>state.approvalLength.trigger)

    const approvalData = useSelector((state) => state.approvalCount.data);

  // const callReports = async () => {
  //   return await viewCallReportForApproval({ viewForApproval: true });
  // };
  // const chemists = async () => {
  //   return await viewTargetsForApproval("chemist");
  // };
  // const city = async () => {
  //   return await viewLocationsForApproval("city");
  // };
  // const doctors = async () => {
  //   return await viewTargetsForApproval("doctor");
  // };
  // const expense = async () => {
  //   return await getExpenseForApproval();
  // };
  // const leaveRequests = async () => {
  //   return await viewLeavesForApproval();
  // };
  // const route = async () => {
  //   return viewLocationsForApproval("route");
  // };
  // const stockists = async () => {
  //   return await viewTargetsForApproval("stockist");
  // };
  // const tp = async () => {
  //   return await viewTPForApproval();
  // };
  // const callPlanningApproval = async () => {
  //   return await viewCallPlanningForApproval();
  // };
  // const tp_status = async () => {
  //   return await viewTPWithStatus();
  // };
  // const sampleApproval = async () => {
  //   return await viewSampleForApproval();
  // };
  // const giftApproval = async () => {
  //   return await viewGiftsForapproval();
  // };
  // const deletionRequest = async ()=>{
  //     return await getAllDeletionRequest();
  // }

  // let apiArray = [
  //   callReports,
  //   tp,
  //   callPlanningApproval,
  //   expense,
  //   leaveRequests,
  //   doctors,
  //   chemists,
  //   stockists,
  //   city,
  //   route,
  //   // tp_status,
  //   sampleApproval,
  //   giftApproval,
  //   deletionRequest
  // ];
  // const parallelApiCall = async () => {
  //   let response = await Promise.all([
  //     apiArray[0](),
  //     apiArray[1](),
  //     apiArray[2](),
  //     apiArray[3](),
  //     apiArray[4](),
  //     apiArray[5](),
  //     apiArray[6](),
  //     apiArray[7](),
  //     apiArray[8](),
  //     apiArray[9](),
  //     apiArray[10](),
  //     // apiArray[11](),
  //   ]);
  //   setData(response);
  // };
  
  // const updateApprovalLength = () =>{
  //  let updatedArray = data.map((e,i)=>{
  //   if(i === approvalLength.index){
  //     return {...e,data:approvalLength.data};
  //   }
  //   return e;
  //  })
  //  setData(updatedArray)
  // }

  // useEffect(() => {
  //  updateApprovalLength() 
  // }, [approvalLength]);
  

  // useEffect(() => {
  //   parallelApiCall();
  // }, []);
  const fetchApprovalCounts = async () => {
    const response = await countApproval();
    if (response) {
      const transformed = links.map((link) => {
        const matchingKey = Object.keys(response.data).find(
          (key) => key === link.accessor
        );
        return {
          ...link,
          count: matchingKey ? response.data[matchingKey] : 0,
        };
      });
      setData(transformed);
    }
   
  };
  useEffect(() => {
    fetchApprovalCounts();
  }, []);

// const responseKeyToAccessor = (key) => {
//   const map = {
//     callReports: "call-report",
//     callPlanning: "cp",
//     tps: "tp",
//     expenses: "expense",
//     leaveRequest: "lr",
//     chemists: "chemist",
//     doctors:"doctor",
//     stockists: "stockist",
//     cities: "city",
//     routes: "route",
//     secondarySales: "secondary sales",
//     primarySales: "primary sales",
//     deletionRequest: "deletion request",
//     preferences: "deletion request",
//   };
//   return map[key] || key;
// };

useEffect(() => {
  if (approvalData && Object.keys(approvalData).length > 0) {
    const transformed = links.map((link) => {
      const matchingKey = Object.keys(approvalData).find(
        (key) => key === link.accessor
      );
      return {
        ...link,
        count: matchingKey ? approvalData[matchingKey] : 0,
      };
    });

    setData(transformed);
  }
}, [approvalData]);

  return (
    <>
      <AdminNav title={title} links={links} response={data}/>

      <Outlet />
    </>
  );
};

export default Approvals;
