import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";
import { approveCount } from "../reducers/approveCountReducer";

const baseURL = `${apiURL}/client/sales/`;
const baseURLV1 = `${apiURL}/client/v1/sales/`;

export const newSales = async (salesData) => {
  const { data } = await axios.post(baseURL + "orgSales", salesData, {
    headers: setAuth(),
  });

  return data;
};

export const viewSales = async (filter) => {
  const { data } = await axios.get(baseURL + "orgSales", {
    params: filter,
    headers: setAuth(),
  });
  console.log(data)
  return data;
};

export const addSecondarySales = async (salesData) => {
  try {
    const response = await axios.post(
      baseURLV1 + "secondary-sales/add",
      salesData,
      {
        headers: setAuth(),
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const addGrossSecondarySales = async (salesData) => {
  try {
    const response = await axios.post(
      baseURL + "secondary-sales/add-gross",
      salesData,
      {
        headers: setAuth(),
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const viewSecondarySales = async (filter) => {
  try {
    const response = await axios.get(baseURLV1 + "secondary-sales/view", {
      params: filter,
      headers: setAuth(),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSecondarySalesPrevMonth = async (filter) => {
  try {
    const {data} = await axios.get(baseURLV1 + "secondary-sales/prev-month", {
      params: filter,
      headers: setAuth(),
    });
    return data;
  } catch (err) {
    throw err;
  }
};
export const getSecondarySalesLatestMonth = async (filter) => {
  try {
    const {data} = await axios.get(baseURL + "secondary-sales/latest-month", {
      params: filter,
      headers: setAuth(),
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const viewGrossSecondarySales = async (filter) => {
  try {
    const response = await axios.get(baseURL + "secondary-sales/view-gross", {
      params: filter,
      headers: setAuth(),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const editSecondarySales = async (salesData) => {
  try {
    const response = await axios.post(
      baseURLV1 + "secondary-sales/edit",
      salesData,
      {
        headers: setAuth(),
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};
export const deleteSecondarySales = async (filter) => {
  try {
    const response = await axios.post(
      baseURLV1 + "secondary-sales/delete",
      filter,
      {
        headers: setAuth(),
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};
export const approveSecondarySales = (filter) =>{
  return async (dispatch) => {
  try{
    const response = await axios.post(baseURL + "secondary-sales/approval/approve" , filter , {
      headers : setAuth(),
    })
    dispatch(approveCount())
    return response ;
    
  }catch(err){
    throw err ;
  }
}
}
export const viewDeletedSecondarySales = async() =>{
  try{
    const response = await axios.get(baseURLV1 + "secondary-sales/view-deleted-secondarySales" , {
      headers : setAuth(),
    })
    return response.data ;
  }catch(err){
    throw err ;
  }
}
export const rejectSecondarySales = (filter) =>{
  return async (dispatch) => {
  try{
    const response = await axios.post(baseURL + "secondary-sales/approval/reject" , filter , {
      headers : setAuth(),
    })
    dispatch(approveCount())
    return response ;
  }catch(err){
    throw err ;
  }
}
}
export const viewSSForApproval = async(filter) =>{
  try{
    const { data } = await axios.get(baseURL + "secondary-sales/approval" , {
      params: filter,
      headers: setAuth(),
    })
    return data ;
  }catch(err){
    throw err ;
  }
}
export const addPrimarySales = async (salesData) => {
  const { data } = await axios.post(baseURLV1 + "primary-sales/add", salesData, {
    headers: setAuth(),
  });
  return data;
};
export const addGrossPrimarySales = async (salesData) => {
  const { data } = await axios.post(baseURL + "primary-sales/add-gross", salesData, {
    headers: setAuth(),
  });
  return data;
};
export const viewPrimarySales = async (filter) => {
  const { data } = await axios.get(baseURLV1 + "primary-sales/view", {
    params: filter,
    headers: setAuth(),
  });
  return data;
};
export const viewGrossPrimarySales = async (filter) => {
  const { data } = await axios.get(baseURL + "primary-sales/view-gross", {
    params: filter,
    headers: setAuth(),
  });
  return data;
};
export const editPrimarySales = async (salesData) => {
  const {data} = await axios.post(baseURLV1 + "primary-sales/edit", salesData, {
    headers:setAuth(),
  })
  return data;
}
export const approvePrimarySales = (filter) =>{
  return async (dispatch) => {
  try{
    const response = await axios.post(baseURL + "primary-sales/approval/approve" , filter , {
      headers : setAuth(),
    })
    dispatch(approveCount())
    return response ;
  }catch(err){
    throw err ;
  }
}
}
export const viewDeletedPrimarySales = async() =>{
  try {
    const response = await axios.get(baseURLV1 + "primary-sales/view-deleted-primarySales" , {
      headers : setAuth(),
    })
    return response.data ;
  }catch(err){
    throw err ;
  }
}
export const rejectPrimarySales = (filter) =>{
  return async (dispatch) => {
  try{
    const response = await axios.post(baseURL + "primary-sales/approval/reject" , filter , {
      headers : setAuth(),
    })
    dispatch(approveCount())
    return response ;
  }catch(err){
    throw err ;
  }
}
}
export const viewPSForApproval = async(filter) =>{
  try{
    const { data } = await axios.get(baseURL + "primary-sales/approval" , {
      params: filter,
      headers: setAuth(),
    })
    return data ;
  }catch(err){
    throw err ;
  }
}

export const deletePrimarySales = async (filter) => {
  const { data } = await axios.post(baseURLV1 + "primary-sales/delete", filter, {
    headers: setAuth(),
  });
  return data;
};
