import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { hideSidenav, showSidenav } from "../../../reducers/appReducer";
import { RiCloseCircleLine } from "react-icons/ri";

const AdminNav = ({ title, links, response }) => {
  const {sideNavVisible} = useSelector(({adminNav}) => adminNav);
  const { pathname } = useLocation();
  const approvalData = useSelector((state) => state.approvalCount.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideSidenav());

    return () => dispatch(showSidenav());
  }, [dispatch]);

  return (
    <nav className="sidebar-inner" style={{display: sideNavVisible ? "flex" : "none" , marginTop:"3rem"}}>
      <div className="sidebar-inner-heading">
        <h2 className="web-app__heading">{title}</h2>
      </div>

      <ul className="side-nav">
        {links.map((link, idx) => (
          <li
            className={`side-nav__item ${
              pathname.includes(link.route) && "inner-nav__active"
            }`}
            key={idx}
          >
            <Link
              to={link.route}
              state={{ index: idx }}
              className="side-nav__link"
            >
              <div className="d-flex align-items-center w-100">
                {link.name}{" "}
                {/* {response?.length&&response[idx]?.data?.length? (
                  <div className="content-length__shower">
                    <span>
                      {response[idx]?.data?.length
                        ? response[idx]?.data?.length
                        : 0}
                    </span>
                  </div>
                ):null} */}
                {response?.find((el) => el.accessor === link.accessor && el.count > 0) && (
                  <div className="content-length__shower">
                    <span>{response.find((el) => el.accessor === link.accessor)?.count}</span>
                  </div>
                )}
                {link.new && (
                  <div className="new-feature__shower">
                    new
                  </div>
                )}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AdminNav;