import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import { Link } from "react-router-dom";
import serialise, { generateSelectUser } from "../../../../utils/serialiseResponse";
import { RiCloseCircleLine } from "react-icons/ri";
import Table from "../../../../components/Table";
import { MdCheckBoxOutlineBlank,MdDeleteOutline } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { allDeletionRequest, deleteDeletionRequest, rejectDeletionRequest } from "../../../../reducers/deletion request/deletionRequestReducer";
import { selectUserStyles } from "../../../../views/public/stylesheets/selectStyles.js";
import Select from "react-select";
import { viewUsersForUtilities } from "../../../../services/utilities";
import {MdOutlineGroup} from "react-icons/md";
import customToast from "../../../../components/CustomToast";
import { BsCheck2Circle } from "react-icons/bs";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

const DeletionRequest = () => {

  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
    
  const [allSelected, setAllSelected] = useState(false);
  const [showDoc, setShowDoc] = useState(true);
  const [showChem, setShowChem] = useState(false);
  const [showStk, setShowStk] = useState(false);

  const requests = useSelector(({ deletionRequest }) => deletionRequest);

  const userSelect = useMemo(
    () => generateSelectUser(users, "fullName"),
    [users]
  );
  
  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setUsers(res?.data);
      });
    } catch (error) {
      customToast.error("error occured Please Try Again");
    }
  }, []);

  const SelectColumnFilter = useCallback(
    ({ column }) => {
      return (
        <div className="search-filter__container" onClick={(e) => { e.stopPropagation() }}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
              <ImCheckboxChecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = false;
                });
                setAllSelected(false);
              }} />
            ) : (
              <ImCheckboxUnchecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = true;
                });
                setAllSelected(true);
              }} />
            )
            }
          </span>
          }
        </div>
      );
    }, [allSelected])

  let type = showDoc ? "doctor" : showChem ? "chemist" : "stockist";

  useEffect(() => {
    dispatch(allDeletionRequest({selectedUser:selectedUser?.value , type: type}));
  }, [dispatch, selectedUser, type])

  const handleDelete = async (data ,type) => {
    if (data.requestedIds.length <= 0 || data.entityIds.length <= 0 || !type ) {
      customToast.error("Please Select Users To Delete.");
      return;
    }
    await dispatch(deleteDeletionRequest({data,type}));
    dispatch(allDeletionRequest({selectedUser:selectedUser?.value , type: type}));
    setShowEdit(false);
  };
  const handleCancel = async (data ,type) => {
    if (data.requestedIds.length <= 0 || data.entityIds.length <= 0 || !type ) {
      customToast.error("Please Select Users To Delete.");
      return;
    }
    await dispatch(rejectDeletionRequest({data,type}));
    dispatch(allDeletionRequest({selectedUser:selectedUser?.value , type: type}));
    setShowEdit(false);
  };

  const tableData = useMemo(() => serialise(requests.data), [requests]);

  const EditPopup = () => {
    const entityIds = Array.isArray(tableData) ? tableData.filter((el) => el?.isSelected).map((el) => el?.entityId) : [];
    const requestedIds = Array.isArray(tableData) ? tableData.filter((el) => el?.isSelected).map((el) => el?._id) : [];
    const data = {
      entityIds,
      requestedIds,
      type,
    };
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Confirm Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  Do you want to delete selected data ?
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleDelete(data ,type)}
                >
                <span className="button-delete__icon-container">
                  <MdDeleteOutline className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Delete</span>
              </button>

              <div className="admin-content-submit-button" 
                onClick={() => handleCancel(data ,type)}
              >
                <button className="button-blue-gradient border-0">
                  Reject
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };
  
  const columns = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "entityType",
        Cell:(props) => <span>{String(props.row.original.entityType).charAt(0).toUpperCase() + String(props.row.original.entityType).slice(1)}</span>
      },
      {
        Header: "Name",
        accessor: "entityName",
      },
      {
        Header: "Requested By",
        accessor: "user",
      },
      {
        Header: "View",
        accessor: "details",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 60,
        maxWidth: 60,
        Cell: (props) => {
          const type = props?.row?.original?.entityType;
          if (type === "doctor"){
            return (
              <Link
                to={"/admin-panel/dcs/view-dcs/details"}
                state={{
                  _id: props?.row?.original?.entityId,
                  type: type,
                }}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            );
          } else if (type === "stockist"){
            return (
              <Link
                to={"/admin-panel/dcs/view-dcs/details-stockist"}
                state={{
                  _id: props?.row?.original?.entityId,
                  type: type,
                }}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            );
          } else if (type === "chemist"){
            return (
              <Link
                to={"/admin-panel/dcs/view-dcs/details-chemist"}
                state={{
                  _id: props?.row?.original?.entityId,
                  type: type,
                }}
                className="util-table-view-details-link"
              >
                <FaEye className="icon-color-tertiary" />
              </Link>
            );
          }
        },
      },
      {
        Header: "Select",
        accessor: "none",
        Cell: (props) => {
          const [local, setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  props.row.original.isSelected = false;
                  setLocal(false);
                }} />
              ) : (
                <MdCheckBoxOutlineBlank onClick={() => {
                  props.row.original.isSelected = true;
                  setLocal(true);
                }} />
              )
              }
            </span>
          );
        },
        minWidth: 75,
        maxWidth: 75,
        disableFilters: false,
        disableSortBy: true,
        Filter: SelectColumnFilter
      },
    ],
    [tableData,SelectColumnFilter]
  );

  return (
    <div className="main-content admin-content">
      <div className="area-creation-content">
        <PageTitle title="Deletion Request" />

        <section className="area-creation-content__info">
          <div className="d-flex justify-content-between align-items-end mb-3">
            <div>
              <p className="util-select-user__label mb-3">Select User</p>
              <div className="util-select-user">
                <MdOutlineGroup className="util-select-user__icon" />
                <Select
                  styles={selectUserStyles}
                  options={userSelect}
                  value={selectedUser}
                  onChange={(selectedUser) => setSelectedUser(selectedUser)}
                  placeholder="Select User"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end gap-5 pe-2">
            <button
              type="submit"
              className="button-submit-green"
              onClick={(e) => {
                e.preventDefault();
                setShowEdit(true);
              }}
            >
              <span className="button-submit-green__icon-container">
                <BsCheck2Circle className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text">Actions</span>
            </button>
            </div>
          </div>
          <div className='d-flex mt-5'> 
            <div className="form__control pe-3" style={{ width: "fit-content", color: "#C7C4E9" }}>
              <label className="toggle-label2 me-3 mb-1">
                <input
                  type="checkbox"
                  checked={showDoc}
                  onClick={() => {
                    setShowDoc(true);
                    setShowChem(false);
                    setShowStk(false);
                  }}
                />
                <span />
              </label>
              <h2 className="web-app__heading me-5">Doctors</h2>
            </div>

            <div className="form__control pe-3" style={{ width: "fit-content", color: "#C7C4E9" }}>
              <label className="toggle-label2 me-3 mb-1">
                <input
                  type="checkbox"
                  checked={showStk}
                  onClick={() => {
                    setShowStk(true);
                    setShowChem(false);
                    setShowDoc(false);
                  }}
                />
                <span />
              </label>
              <h2 className="web-app__heading me-5">Stockists</h2>
            </div>

            <div className="form__control pe-3" style={{ width: "fit-content", color: "#C7C4E9" }}>
              <label className="toggle-label2 me-3 mb-1">
                <input
                  type="checkbox"
                  checked={showChem}
                  onClick={() => {
                    setShowChem(true);
                    setShowDoc(false);
                    setShowStk(false);
                  }}
                />
                <span />
              </label>
              <h2 className="web-app__heading me-5">Chemists</h2>
            </div>
          </div>          
          {tableData?.length > 0 && (
            <h2 className="web-app__heading mb-4 ms-2 mt-5">
              Showing ({tableData?.length ? tableData?.length : 0}) Entries
            </h2>
          )}
          <div className="filter-table">
            <Table columns={columns} data={tableData} />
          </div>
          {showEdit ? <EditPopup /> : null}
        </section>
      </div>
    </div>
  );
};

export default DeletionRequest;
