import axios from "axios";
import { apiURL } from "../utils/helper";
import { setAuth } from "./user";

const baseUrl = `${apiURL}/client/notification/`;
const baseUrlV1 = `${apiURL}/client/v1/notification/`;

export const getNotifications = async () => {
  const { data } = await axios.get(baseUrlV1, {
    headers: setAuth(),
  });

  return data;
};
export const countApproval = async () => {
  const {data} = await axios.get(`${baseUrlV1}/approval-count`,{
    headers: setAuth(),
  })
 
  return data;
}


// export const getReminderForDashboard = async () => {
//   const { data } = await axios.get(baseUrl + "reminder-for-dashboard", {
//     headers: setAuth(),
//   });

//   return data;
// };

// export const newReminder = async (reminder) => {
//   const { data } = await axios.post(baseUrl, reminder, {
//     headers: setAuth(),
//   });

//   return data;
// };

// export const deleteReminderById = async (reminderId) => {
//   const { data } = await axios.delete(baseUrl+"delete", {
//     params:{reminderId}, 
//     headers: setAuth(),
//   });

//   return data;
// };
