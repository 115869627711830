import React from "react";
import styles from "./Loader.module.css";
import stickImage from "../../views/public/img/logo.png"; 

const Loader = ({loadingMsg}) => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader}>
        <div className={styles.cShape}></div> 
        <img src={stickImage} alt="Stick" className={styles.stick} />
      </div>
      {loadingMsg && <p className={styles.loadingMsg}>{loadingMsg}</p>}
    </div>
  );
};

export default Loader;
