import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageTitle from '../../../../components/PageTitle';
import { getGeoLocationApprovalData, updateApprovalStatus } from '../../../../services/geoFencing';
import customToast from '../../../../components/CustomToast';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../../components/Table';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs';
import { RiCloseCircleLine } from 'react-icons/ri';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { updateTrigger } from '../../../../reducers/approveLengthReducer';
import { UpdateStatusApproval } from '../../../../reducers/geoFencing';

const ApproveGeoFencing = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  const [data, setData] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showDoc, setShowDoc] = useState(true);
  const [showChem, setShowChem] = useState(false);
  const [showStk, setShowStk] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [approvalData, setApprovalData] = useState([]);
  const [updateToggle, setUpdateToggle] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const containerStyle = {
    width: '400px',
    height: '400px',
    border: '3px solid #36ABF9',
  };

  const center = {
    lat: latitude || 0,
    lng: longitude || 0
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY
  });

  const SelectColumnFilter = useCallback(
    ({ column, columns }) => {
      return (
        <div className="search-filter__container" onClick={(e) => { e.stopPropagation() }}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
              <ImCheckboxChecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = false;
                });
                setAllSelected(false);
              }} />
            ) : (
              <ImCheckboxUnchecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = true;
                });
                setAllSelected(true);
              }} />
            )
            }
          </span>
          }
        </div>
      );
    }, [allSelected])

  const fetchData = useCallback(async () => {
    try {
      let flag = {
        submitterId: loggedIn?.user?._id,
        viewForPending: true,
        type: showDoc ? "doc" : showChem ? "chem" : "stk",
      };

      const res = await getGeoLocationApprovalData({ flag: flag });
      setData(res?.data);
      dispatch(
        updateTrigger({ index: 13, data: res.data })
      );
    } catch (error) {
      console.log(error);
      customToast.error("Error occurred !");
    }
  }, [showChem, showDoc, showStk]);

  useEffect(() => {
    fetchData();
  }, [showChem, showDoc, showStk, updateToggle, dispatch]);

  const handleApprove = async (data) => {
    
    try {
      if(data.length === 0)return customToast.error("Select to approve !");
      
        const type = showDoc ? "doctor" : showChem ? "chemist" : "stockist";

        let payload;
        if (data.length === 1) {
          payload = { id: data[0], isApprovedStatus: "true" }; 
        } else {
          payload = { ids: data, isApprovedStatus: "true" }; 
        }
      
      await dispatch(UpdateStatusApproval(payload, type));
      setData(prevData => prevData.map(item => ({ ...item, isSelected: false })));
      setShowEdit(false);
      setAllSelected(false);
      setUpdateToggle(!updateToggle);

       customToast.success("Approved successfully !");

    } catch (error) {
      console.log(error);
      customToast.error("Error Occurred !");
    }
  };
  const handleReject = async (data) => {
    try {
      if(data.length === 0)return customToast.error("Select to Reject !");
      
      const type = showDoc ? "doctor" : showChem ? "chemist" : "stockist";

      let payload;
      if (data.length === 1) {
        payload = { id: data[0], isApprovedStatus: "false" }; 
      } else {
        payload = { ids: data, isApprovedStatus: "false" }; 
      }
      console.log(payload)

  
      await updateApprovalStatus(payload, type);
      setData(prevData => prevData.map(item => ({ ...item, isSelected: false })));
      setAllSelected(false);
      setUpdateToggle(prev => !prev);
      customToast.success("Rejected successfully !");
    } catch (error) {
      console.error("Error in handleReject:", error);
      customToast.error("An error occurred while rejecting!");
    }
  };


  const EditPopup = ({tableData}) => {
    const data = [];
    tableData.forEach((el,idx)=>{
      if(el?.isSelected){
        data.push(el?._id);
      }
    })
    
    return (
      <section className="edit-popup" onClick={(e) =>
        showEdit
          ? e.target.className === "edit-popup"
            ? setShowEdit(false)
            : null
          : null
      }>
        <div className="edit-content">
          <div className="edit-popup__heading">
            <h2 className="web-app__heading">Confirm Details</h2>
            <RiCloseCircleLine
              className="edit-popup__close"
              onClick={() => setShowEdit(false)}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEdit(false);
            }}
          >
            <div className="edit-form">
              <div>
                <label htmlFor="division">
                  {`Are you sure you want to ${data.length} approve ?`}
                </label>
                <br />
              </div>
            </div>

            <div className="edit-form-button-container">
              <button
                type="submit"
                className="button-submit-green mt-4"
                onClick={() => handleApprove(data)}
              >
                <span className="button-submit-green__icon-container">
                  <BsCheck2Circle className="button-submit-green__icon" />
                </span>
                <span className="button-submit-green__text">Approve</span>
              </button>

              <button
                type="submit"
                className="button-delete mt-4"
                onClick={() => handleReject(data)}
              >
                <span className="button-delete__icon-container">
                  <BsXCircle className="button-delete__icon" />
                </span>
                <span className="button-delete__text">Reject</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  };

  const ViewMap = () => {
    return isLoaded ? (
      <section
        className='edit-popup'
        onClick={(e) =>
          showMap ?
            e.target.className === "edit-popup"
              ? setShowMap(false)
              : null
            :
            null
        }
      >
       <div style={{position:"relative"}}>
            <RiCloseCircleLine
              className="map-popup__close"
              onClick={() => setShowMap(false)}
              size={25} 
              style={{ cursor: 'pointer' }}
            />
          <GoogleMap 
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            <Marker position={center} />
          </GoogleMap>
        </div>
      </section>
    ) : (
      <div style={{ color: "white" }}>
        ....Loading Map
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: showDoc ? "name" : "businessName"
      },
      {
        Header: "Location",
        accessor: "",
        Cell: (props) => {
          let data = props?.row?.original;
          return (
            <div>
              <CiLocationOn
                className='icon-color-tertiary'
                style={{ cursor: "pointer" }}
                size={25}
                onClick={() => {
                  setLatitude(data?.geolocation?.latitude);
                  setLongitude(data?.geolocation?.longitude);
                  setShowMap(true)
                }}
              />
            </div>
          )
        }
      },
      {
        Header: "Select",
        accessor: "none",
          Cell: (props) => {
                 const [local, setLocal] = useState(props?.row?.original?.isSelected || false);
                 return (
                   <span
                     className="react-table-view-link display-6"
                   >
                     {local ? (
                       <ImCheckboxChecked onClick={() => {
                         // handleSelect(props?.row?.original?.sr);
                         props.row.original.isSelected = false;
                         setLocal(false);
                       }} />
                     ) : (
                       <MdCheckBoxOutlineBlank onClick={() => {
                         // handleSelect(props?.row?.original?.sr);
                         props.row.original.isSelected = true;
                         setLocal(true);
                       }} />
                     )
                     }
                   </span>
                 );
               },
               minWidth: 75,
               maxWidth: 75,
               disableFilters: false,
               disableSortBy: true,
               Filter: SelectColumnFilter
      },
    ],
    [SelectColumnFilter, data]
  );

  return (
    <div className='main-content admin-content'>
      <div className='admin-creation-content'>
        <PageTitle title="Approve Geo Fencing" />

        <section className="area-creation-content__info">

          <div className='d-flex'>
            <div className="form__control pe-3" style={{ width: "fit-content", color: "#C7C4E9" }}>
              <label className="toggle-label2 me-3 mb-1">
                <input
                  type="checkbox"
                  checked={showDoc}
                  onClick={() => {
                    setShowDoc(true);
                    setShowChem(false);
                    setShowStk(false);
                    setAllSelected(false);
                  }}
                />
                <span />
              </label>
              <h2 className="web-app__heading me-5">Doctors</h2>
            </div>

            <div className="form__control pe-3" style={{ width: "fit-content", color: "#C7C4E9" }}>
              <label className="toggle-label2 me-3 mb-1">
                <input
                  type="checkbox"
                  checked={showStk}
                  onClick={() => {
                    setShowStk(true);
                    setShowChem(false);
                    setShowDoc(false);
                    setAllSelected(false);
                  }}
                />
                <span />
              </label>
              <h2 className="web-app__heading me-5">Stockists</h2>
            </div>

            <div className="form__control pe-3" style={{ width: "fit-content", color: "#C7C4E9" }}>
              <label className="toggle-label2 me-3 mb-1">
                <input
                  type="checkbox"
                  checked={showChem}
                  onClick={() => {
                    setShowChem(true);
                    setShowDoc(false);
                    setShowStk(false);
                    setAllSelected(false);
                  }}
                />
                <span />
              </label>
              <h2 className="web-app__heading me-5">Chemists</h2>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-5 pe-2">
            <button
              type="submit"
              className="button-submit-green"
              onClick={(e) => {
                e.preventDefault();
                setShowEdit(true);
              }}
            >
              <span className="button-submit-green__icon-container">
                <BsCheck2Circle className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text">Actions</span>
            </button>
          </div>
          <div className='web-app__heading mt-5'>
            {`Showing (${data?.length}) Entries`}
          </div>

          <div className='filter-table'>
            <Table data={data} columns={columns} />
          </div>
        </section>
        {showEdit ? <EditPopup tableData={data} /> : null}
        {showMap ? <ViewMap /> : null}
      </div>
    </div>
  );
}

export default ApproveGeoFencing;
