import { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { generateSelectUser } from "../../../../utils/serialiseResponse";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import customToast from "../../../../components/CustomToast";
import {
  clearUsers,
  setUsers,
} from "../../../../reducers/users/employeeReducer";
import serialise from "../../../../utils/serialiseResponse";

import { allotDcs } from "../../../../services/allotDcs";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew, MdCheckBoxOutlineBlank } from "react-icons/md";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import Table from "../../../../components/Table";
import { clearDoctors, setDoctors } from "../../../../reducers/targets/doctor";
import { clearChemists, setChemists } from "../../../../reducers/targets/chemist";
import { clearStockists, setStockists } from "../../../../reducers/targets/stockist";
import Loader from "../../../../components/Loader";

const typeOptions = [
  {
    value: 1,
    label: "Doctor",
  },
  {
    value: 2,
    label: "Chemist",
  },
  {
    value: 3,
    label: "Stockist",
  },
];

const AdminAllotDCS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedIn = useSelector(({ user }) => user);
  const employees = useSelector(({ employee }) => employee);
  const doctor = useSelector(({ doctor }) => doctor);
  const chemist = useSelector(({ chemist }) => chemist);
  const stockist = useSelector(({ stockist }) => stockist);

  const [type, setType] = useState(typeOptions[0]);
  const [fromEmployee, setFromEmployee] = useState(null);
  const [toEmployee, setToEmployee] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [docs, setDocs] = useState([]);
  const [chem, setChem] = useState([]);
  const [stk, setStk] = useState([]);
  const [lodingState, setLodingState] = useState(false);

  const resetForm = () => {
    setType(null);
    setFromEmployee(null);
    setToEmployee(null);
  };

  const tableData = useMemo(() => {
    return type?.label?.toLowerCase() === "doctor"
      ? serialise(doctor?.data)
      : type?.label?.toLowerCase() === "chemist"
        ? serialise(chemist?.data)
        : serialise(stockist?.data);
  }, [doctor, stockist, chemist, type]);


  let data = [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (toggle === true) {
      if (!type || !toEmployee)
        return customToast.error("Please select all fields");
    }
    else if (!type || !toEmployee || !fromEmployee) {
      return customToast.error("Please select all fields");
    }

    if (toggle) {
      tableData.forEach((el) => {
        if (el?.isSelected) {
          data.push(el?._id);
        }
      });
      if (data.length === 0) {
        return customToast.error(`Select ${type?.label} for Allotment`);
      }
    }

    let details = {
      type: type?.label,
      from: fromEmployee?.value,
      to: toEmployee?.value,
      IdArray: data
    };
    try {
      setLodingState(true)
      let response = await allotDcs(details, type?.label);
      if (response) customToast.success(`${type?.label}s alotted successfully`);
      resetForm();
      setLodingState(false)
    } catch (err) {
      customToast.error("An unexpected error occured !");
    }
  };

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const SearchColumnFilter = useCallback(
    ({ column, columns }) => {
      return (
        <div className="search-filter__container" onClick={(e) => { e.stopPropagation() }}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
              <ImCheckboxChecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = false;
                });
                setAllSelected(false);
              }} />
            ) : (
              <ImCheckboxUnchecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = true;
                });
                setAllSelected(true);
              }} />
            )
            }
          </span>
          }
        </div>
      );
    }, [allSelected]);

  const columnData = useMemo(
    () => [

      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      }, {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Working Area",
        accessor: "workingArea",
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Select",
        accessor: "select",
        Cell: (props) => {
          const [local, setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false;
                  setLocal(false);
                }} />
              ) : (
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true;
                  setLocal(true);
                }} />
              )
              }
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: false,
        disableSortBy: true,
        Filter: SearchColumnFilter
      },
    ],
    [type, navigate, SearchColumnFilter, tableData]
  );

  const chemColumnData = useMemo(
    () => [

      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 120,
        maxWidth: 120,
      }, {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "chemistContact",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Working Area",
        accessor: "workingArea",
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Select",
        accessor: "select",
        Cell: (props) => {
          const [local, setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false;
                  setLocal(false);
                }} />
              ) : (
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true;
                  setLocal(true);
                }} />
              )
              }
            </span>
          );
        },
        minWidth: 100,
        maxWidth: 100,
        disableFilters: false,
        disableSortBy: true,
        Filter: SearchColumnFilter
      },
    ],
    [type, navigate, SearchColumnFilter, tableData]
  );

  const stkColumnData = useMemo(
    () => [
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 120,
        maxWidth: 120,
      }, {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "stockistContact",
        disableSortBy: true,
        disableFilters: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Working Area",
        accessor: "workingArea",
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: "Select",
        accessor: "select",
        Cell: (props) => {
          const [local, setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false;
                  setLocal(false);
                }} />
              ) : (
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true;
                  setLocal(true);
                }} />
              )
              }
            </span>
          );
        },
        minWidth: 100,
        maxWidth: 100,
        disableFilters: false,
        disableSortBy: true,
        Filter: SearchColumnFilter
      },
    ],
    [type, navigate, SearchColumnFilter, tableData]
  );


  useEffect(() => {
    dispatch(setUsers("user"));

    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch]);


  useEffect(() => {
    let data = {
      selectedUser: fromEmployee?.value,
      showApproved: true,
      admin: true
    };
    dispatch(setDoctors(data));
    setDocs(doctor?.data);

    return () => dispatch(clearDoctors());
  }, [dispatch, fromEmployee]);

  useEffect(() => {
    let data = {
      selectedUser: fromEmployee?.value,
      showApproved: true
    };
    dispatch(setChemists(data));
    setChem(chemist?.data);

    return () => dispatch(clearChemists());
  }, [dispatch, fromEmployee]);

  useEffect(() => {
    let data = {
      selectedUser: fromEmployee?.value,
      showApproved: true
    };
    dispatch(setStockists(data));
    setStk(stockist?.data);

    return () => dispatch(clearStockists());
  }, [dispatch, fromEmployee]);



  return (

    <div className="main-content admin-content">
      {lodingState ? <Loader loadingMsg="Please Wait it may take more than 1 min" /> : (
        <div className="area-creation-content">
        <div className="admin-creation-content__heading justify-content-between align-items-center py-5">
          <h2
            className="web-app__heading"
            style={{ display: "flex", alignItems: "center" }}
          >
            <MdArrowBackIosNew
              className="admin-creation-content__back-button"
              onClick={() => navigate(-1)}
            />
            <span style={{ display: "inline-block", margin: "0 1rem" }}>
              Allot Doctor / Chemist / Stockist
            </span>
          </h2>
          <div className="d-flex gap-4 align-items-center">
            <div className="form__control " style={{ width: "fit-content", color: "#C7C4E9" }}>
              <span>Allot Partially</span>
              <label className="toggle-label mx-3" style={{ marginBottom: "0", border: "1px solid slategray", borderRadius: "1.6rem" }}>
                <input
                  type="checkbox"
                  checked={toggle}
                  onClick={(e) => {
                    setToggle(!toggle);
                  }
                  }
                />
                <span />
              </label>
            </div>
          </div>
        </div>
        <section className="area-creation-content__form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <label htmlFor="type">
                  Select Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="type"
                  id="type"
                  value={type}
                  options={typeOptions}
                  onChange={(e) => setType({ ...e })}
                  styles={adminStyles}
                  placeholder="Select Doc / Chem / Stk"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 ">
                <label htmlFor="employee">
                  Allot From &nbsp;
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="employee"
                  id="employee"
                  value={fromEmployee}
                  options={employeeSelect}
                  onChange={({ value, label }) =>
                    setFromEmployee({ value, label })
                  }
                  styles={adminStyles}
                  placeholder="Select Employee"
                />
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 ">
                <label htmlFor="employee">
                  Allot To &nbsp;
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  name="employee"
                  id="employee"
                  value={toEmployee}
                  options={employeeSelect}
                  onChange={({ value, label }) =>
                    setToEmployee({ value, label })
                  }
                  styles={adminStyles}
                  placeholder="Select Employee"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap mt-5">
              <div className="area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient">
                  Allot
                </button>
              </div>
            </div>
          </form>
          {toggle &&
            <div className="filter-table">
              {tableData.length > 0 && type && (
                <h2 className="web-app__heading ms-2 mb-4">
                  Showing ({tableData.length}) Entries
                </h2>
              )}
              <Table
                columns={
                  type?.label?.toLowerCase() === "doctor"
                    ? columnData
                    : type?.label?.toLowerCase() === "chemist"
                      ? chemColumnData
                      : type?.label?.toLowerCase() === "stockist"
                        ? stkColumnData
                        : []
                }
                data={tableData}
              />
            </div>}
        </section>
      </div>
      )}
    </div>
  );
};

export default AdminAllotDCS;
