import { useEffect, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { adminStyles } from "../../../public/stylesheets/selectStyles";
import serialise from "../../../../utils/serialiseResponse";

import { useNavigate } from "react-router-dom";

import Table from "../../../../components/Table";
import { generateSelectUser } from "../../../../utils/serialiseResponse";
import { viewUsersForUtilities } from "../../../../services/utilities";
import { viewTargets } from "../../../../services/targets";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import customToast from "../../../../components/CustomToast";
import { deallotDCS } from "../../../../services/allotDcs";
import Loader from "../../../../components/Loader";

const typeOptions = [
  { value: 1, label: "Doctor" },
  { value: 2, label: "Chemist" },
  { value: 3, label: "Stockist" },
];

const AdminDeAllotDCS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [type, setType] = useState(typeOptions[0]);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [docs, setDocs] = useState([]);
  const [chem, setChem] = useState([]);
  const [stk, setStk] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [trigger, setTrigger] = useState(false); //used in useEffect to re-render and display the updated DCS data, when de-allocation takes place
  const [lodingState, setLodingState] = useState(false);

  const employeeSelect = useMemo(
    () => generateSelectUser(employees, "fullName"),
    [employees]
  );

  const SearchColumnFilter = useCallback(
    ({ column, columns }) =>{
      return (
        <div className="search-filter__container" onClick={(e)=>{e.stopPropagation()}}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
                <ImCheckboxChecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = false ;
                  });
                  setAllSelected(false);
                }}/>
              ):(
                <ImCheckboxUnchecked onClick={(e) => {
                  e.preventDefault();
                  column?.filteredRows?.forEach((el,idx)=>{
                    el.original.isSelected = true ;
                  });
                  setAllSelected(true);
                }}/>
              )
              }
          </span>
          }
        </div>
  );},[allSelected]);

  const tableData = useMemo(() => {
    return type?.label?.toLowerCase() === "doctor"
      ? serialise(docs)
      : type?.label?.toLowerCase() === "chemist"
        ? serialise(chem)
        : serialise(stk);
  }, [docs,chem,stk]);

  const data = [];

  const handleSubmit = async (e) =>{
    e.preventDefault();

    if (!type || !selectedUser)
      return customToast.error("Please select all fields");

      tableData.forEach((el)=>{
        if(el?.isSelected){
          data.push(el?._id);
        }
      });

      if(data.length === 0)
      return customToast.error(`Select ${type?.label} to De-Allot`);
      
      let details = {
        userId : selectedUser?.value,
        DoctorsArray : data,
      };

      try {
        setLodingState(true)
        let response = await deallotDCS(details,type?.label);
        if(response)  {
          customToast.success("De-alloted successfully");
          setTrigger((prev) => !prev);
        }
        setLodingState(false)
      } catch (error) {
        customToast.error("Error Occurred");
      }
  };

  const columnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      }, {
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Degree",
        accessor: "degree",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Specialization",
        accessor: "specialization",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Select",
        accessor:"select",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 70,
        maxWidth: 70,
        disableFilters: false,
        disableSortBy: true,
        Filter:SearchColumnFilter
      },
    ],
    [type, navigate,SearchColumnFilter,tableData]
  );

  const chemColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },{
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "chemistContact",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Select",
        accessor:"select",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 110,
        maxWidth: 110,
        disableFilters: false,
        disableSortBy: true,
        Filter:SearchColumnFilter
      },
    ],
    [type, navigate,SearchColumnFilter,tableData]
  );

  const stkColumnData = useMemo(
    () => [
      // {
      //   Header: "Sr. No",
      //   accessor: "sr",
      //   disableFilters: true,
      //   minWidth: 40,
      //   maxWidth: 40,
      // },
      {
        Header: "Business Name",
        accessor: "businessName",
        minWidth: 80,
        maxWidth: 80,
      },{
        Header: "UID",
        accessor: "uid",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Propreitor Name",
        accessor: "name",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Mobile Number",
        accessor: "stockistContact",
        disableSortBy: true,
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "HQ",
        accessor: "city",
        minWidth: 85,
        maxWidth: 85,
      },
      {
        Header: "Select",
        accessor:"select",
        Cell: (props) => {
          const [local,setLocal] = useState(props?.row?.original?.isSelected || false);
          return (
            <span
              className="react-table-view-link display-6"
            >    
              {local ? (
                <ImCheckboxChecked onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = false ;
                  setLocal(false); 
                }}/>
              ):(
                <MdCheckBoxOutlineBlank onClick={() => {
                  // handleSelect(props?.row?.original?.sr);
                  props.row.original.isSelected = true ;
                  setLocal(true);
                }}/>
              )
              }
            </span>
          );
        },
        minWidth: 110,
        maxWidth: 110,
        disableFilters: false,
        disableSortBy: true,
        Filter:SearchColumnFilter
      },,
    ],
    [type, navigate,SearchColumnFilter,tableData]
  );

  useEffect(() => {
    async function asyncDocFetch() {
      let data = {
        selectedUser: selectedUser?.value,
        showApproved:true,
        admin: true
      };
      if(selectedUser?.value){
        setLodingState(true)
        let res = await viewTargets("doctor", data);
        setDocs(res.data);
        setLodingState(false)
      }
    }

    asyncDocFetch();
  }, [selectedUser,type,trigger]);

  useEffect(() => {
    async function asyncStkFetch() {
      let data = {
        selectedUser: selectedUser?.value,
        showApproved:true
      };
      if(selectedUser?.value){
        setLodingState(true)
        let res = await viewTargets("stockist", data);
        setStk(res.data);
        setLodingState(false)
      }
    }

    asyncStkFetch();
  }, [selectedUser,type,trigger]);

  useEffect(() => {
    async function asyncChemFetch() {
      let data = {
        selectedUser: selectedUser?.value,
        showApproved:true
      };
      if(selectedUser?.value){
        setLodingState(true)
        let res = await viewTargets("chemist", data);
        setChem(res.data);
        setLodingState(false)
      }
    }

    asyncChemFetch();
  }, [selectedUser,type,trigger]);

  useEffect(() => {
    try {
      viewUsersForUtilities().then((res) => {
        setEmployees(res?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);


  return (
    <main className="main-content admin-content">
      {lodingState ? <Loader loadingMsg="Please Wait it may take more than 1 min" /> : (
      <div className="area-creation-content">
        <PageTitle title="De-Allot Doc / Chem / Stk" hierarchy="true"/>

        <section className="area-creation-content__info">
          <form>
            <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3">
              <label htmlFor="type" style={{marginTop:"1.5rem", marginBottom:"1.5rem"}}>Select Type</label>
              <br />
              <Select
                name="type"
                id="type"
                value={type}
                options={typeOptions}
                onChange={(e) => setType({ ...e })}
                styles={adminStyles}
                placeholder="Doc / Chem / Stk"
                />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <label htmlFor="type" style={{marginTop:"1.5rem", marginBottom:"1.5rem"}}>Select User</label>
              <br />
              <Select
                name="type"
                id="type"
                value={selectedUser}
                options={employeeSelect}
                onChange={(e) => setSelectedUser({ ...e })}
                styles={adminStyles}
                placeholder="Select User"
                />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4" style={{paddingTop:"5rem"}}>
                <button
                  type="submit"
                  className="button-blue-gradient"
                  style={{marginLeft:"40rem"}}
                  onClick={handleSubmit}
                >DeAllot
                </button>
              </div>
            </div>
          </form>
          <div className="filter-table">
            {tableData.length > 0 && (
              <h2 className="web-app__heading ms-2 mb-4">
                Showing ({tableData.length}) Entries
              </h2>
            )}
            <Table
              columns={
                type?.label?.toLowerCase() === "doctor"
                  ? columnData
                  : type?.label?.toLowerCase() === "chemist"
                    ? chemColumnData
                    : stkColumnData
              }
              data={tableData}
            />
          </div>
        </section>
      </div>
      )}
    </main>
  );
};

export default AdminDeAllotDCS;
