import React from 'react'
import { useEffect, useMemo, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { addUserToCampaign, getCampaign, getCampaignDetails, removeUserFromCampaign } from '../../../../../services/campaign';
import PageTitle from '../../../../../components/PageTitle';
import Table from '../../../../../components/Table';
import { clearUsers, setUsers } from '../../../../../reducers/users/employeeReducer';
import serialise from '../../../../../utils/serialiseResponse';
import { ImCheckboxChecked } from "react-icons/im";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import customToast from '../../../../../components/CustomToast';


const AddUserInCampaign = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const params = useParams()
    const { campaignId } = params;
    const userData = useSelector(({ employee }) => employee);
    const [campaigns, setCampaigns] = useState([]);
    const [selectedUser, setSelectedUser] = useState([])
    const [singleCampaignData, setSingleCampaignData] = useState([])
    
    useEffect(() => {
      dispatch(setUsers("user"));
      getCampaign().then(({data}) => setCampaigns(data)).catch((err)=> console.log(err));
      return () => {
        dispatch(clearUsers());
      };
    }, [dispatch]);


    const campaignUserIds = new Set(singleCampaignData?.users?.map(user => user._id)); 
    const updatedUsers = userData.map(user => ({
      ...user,
      isSelected: campaignUserIds.has(user._id)
    }));


    useEffect(() => {
      // if (!campaignId) {
      //     console.error("Campaign ID is missing!");
      //     return;
      // }
  
      const fetchCampaignData = async () => {
          try {
              const { data } = await getCampaignDetails(campaignId);
              setSingleCampaignData(data)
            } catch (error) {
              console.error("Error fetching campaigns:", error);
          }
      };
  
      fetchCampaignData();
  }, []);
  
    const columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "firstName",
          
        },
        {
          Header: "Select",
          accessor: "",
          Cell: (props) => {
            const rowId = props.row.original._id;

            const isAlreadySelected = singleCampaignData?.users?.some((user) => user._id?.toString() === rowId.toString()) 
            const [local, setLocal] = useState(
              props?.row?.original?.isSelected || isAlreadySelected || false
            );
            
            const handleUserSelection = () => {
              if (local) {
                
                setSingleCampaignData((prev) => ({
                  ...prev,
                  users: prev.users.filter((user) => user._id !== rowId),
                }));
              } else {
              
                setSingleCampaignData((prev) => ({
                  ...prev,
                  users: [...prev.users, props.row.original],
                }));
              }
              setLocal(!local);
            };
            return (
              <span className="react-table-view-link display-6">
                {local ? (
                  <ImCheckboxChecked
                    onClick={() => {
                      props.row.original.isSelected = false;
                      setLocal(false);
                      handleUserSelection()
                      handleRowSelection();
                    }}
                  />
                ) : (
                  <MdCheckBoxOutlineBlank
                    onClick={() => {
                      props.row.original.isSelected = true;
                      setLocal(true);
                      handleUserSelection()
                      handleRowSelection();
                    }}
                  />
                )}
              </span>
            );
          },
          minWidth: 70,
          maxWidth: 70,
          disableFilters: true,
          disableSortBy: true,
        },
      ],
      [userData,selectedUser]
    );

    const handleRowSelection = () => {
      setSelectedUser(userData.filter((el) => el?.isSelected));
    };
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await addUserToCampaign(campaignId, { userIds:selectedUser }); 
        customToast.success(response?.message || "Users added successfully!");
        navigate(`/admin-panel/holidays/allcampaign`)
        
      } catch (error) {
        console.error("API Error:", error);  
        customToast.error(
          error?.response?.data?.message || "Sorry! something went wrong."
        );
      }
     };

     const handleRemoveUserFromCampaign = async (e) => {
      e.preventDefault();
      try {
        const response = await removeUserFromCampaign(campaignId, { userIds:singleCampaignData.users });  
        customToast.success(response?.message || "Users remove successfully!");
        navigate(`/admin-panel/holidays/allcampaign`)
      } catch (error) {
        console.error("API Error:", error);  
        customToast.error(
          error?.response?.data?.message || "Sorry! something went wrong."
        );
      }
     };
   
    
    return (
      <div className="main-content admin-content">
        <div className="area-creation-content">
          <PageTitle title="Add User" />
  
          <section className="area-creation-content__info">
            {userData.length > 0 && (
              <h2 className="web-app__heading mb-4 ms-2">
                Showing ({userData.length}) Entries
              </h2>
            )}
            <div className="filter-table">
              { userData.length > 0 && (
                <Table columns={columns} data={userData} />
              )}
            </div>
            {/* {selectedUser.length > 0 ? (
            <div className="admin-content-submit-button d-flex justify-content-center mt-2">
              <button onClick={handleSubmit} type="submit" className="button-blue-gradient">
                Add User
              </button>
            </div>  
            ):(
              <div className="admin-content-submit-button d-flex justify-content-center mt-2">
                <button onClick={handleRemoveUserFromCampaign} type="submit" className="button-blue-gradient">
                  Remove User
                </button>
              </div>
            )} */}
           {selectedUser?.length > 0 && (
            <div className="admin-content-submit-button d-flex justify-content-center mt-2">
              <button onClick={handleSubmit} type="submit" className="button-blue-gradient">
                Add User
              </button>
           </div>
           )}
           {singleCampaignData?.users?.length > 0 && selectedUser?.length === 0 && (
            <div className="admin-content-submit-button d-flex justify-content-center mt-2">
              <button onClick={handleRemoveUserFromCampaign} type="submit" className="button-blue-gradient">
                Remove User
              </button>
            </div>
           )}
          </section>
        </div>
      </div>
    );
}

export default AddUserInCampaign